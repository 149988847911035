<template>
  <div class="tradeinDetails" v-if="details">
    <header>
      <h2>{{ t.tradein_details }}</h2>
      <button v-if="tradein.listingId" class="gotoButton" @click="goToListing">
        {{ t['view_listing_details'] }}
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.5 0.5H11.25C11.6484 0.5 12 0.851562 12 1.25V5C12 5.42188 11.6484 5.75 11.25 5.75C10.8281 5.75 10.5 5.42188 10.5 5V3.07812L5.76562 7.78906C5.48438 8.09375 4.99219 8.09375 4.71094 7.78906C4.40625 7.50781 4.40625 7.01562 4.71094 6.73438L9.42188 2H7.5C7.07812 2 6.75 1.67188 6.75 1.25C6.75 0.851562 7.07812 0.5 7.5 0.5ZM1.875 1.25H4.5C4.89844 1.25 5.25 1.60156 5.25 2C5.25 2.42188 4.89844 2.75 4.5 2.75H1.875C1.66406 2.75 1.5 2.9375 1.5 3.125V10.625C1.5 10.8359 1.66406 11 1.875 11H9.375C9.5625 11 9.75 10.8359 9.75 10.625V8C9.75 7.60156 10.0781 7.25 10.5 7.25C10.8984 7.25 11.25 7.60156 11.25 8V10.625C11.25 11.6797 10.4062 12.5 9.375 12.5H1.875C0.820312 12.5 0 11.6797 0 10.625V3.125C0 2.09375 0.820312 1.25 1.875 1.25Z" fill="black" />
        </svg>
      </button>
    </header>
    <section class="top">
      <div>
        <h4>{{ tradein.make }} {{ tradein.model }}</h4>
        <p>{{ tradein.variant }} &bull; {{ tradein.year }} &bull; {{ tradein.kilometrage?.toLocaleString(language) }} {{ t['km'] }}</p>
      </div>
      <div class="images" v-if="tradein?.pictures?.length > 0">
        <img v-for="picture in tradein.pictures" :key="picture.url" :src="getImage(picture.url)" @error="imageError = true" />
      </div>
      <dl>
        <dt>{{ t.registration_date }}</dt>
        <dd>{{ registrationDate }}</dd>
        <dt>{{ t.registration_type }}</dt>
        <dd>{{ tradein.registrationType }}</dd>
        <dt>{{ t.color }}</dt>
        <dd>{{ tradein.color }}</dd>
        <dt>{{ t.fuel_type }}</dt>
        <dd>{{ tradein.fuelType }}</dd>
        <dt>{{ t.accident_history }}</dt>
        <dd>{{ tradein.accident ? t.yes : t.no }}</dd>
        <dt>{{ t.outstanding_financing }}</dt>
        <dd>{{ formatPrice({ value: tradein.outstandingFinanceAmount }, tradein.currency) }}</dd>
        <dt>{{ t.number_of_keys }}</dt>
        <dd>{{ tradein.numberOfKeys }}</dd>
        <dt>{{ t.service_history }}</dt>
        <dd>{{ tradein.serviceType }}</dd>
        <dt>{{ t.current_condition }}</dt>
        <dd>{{ tradein.carCondition }}</dd>
      </dl>
    </section>
    <section class="bottom" v-if="showOffer">
      <h4>{{ t.offer_details }}</h4>
      <dl>
        <dt>{{ t.tradein_total_value }}</dt>
        <dd>{{ formatPrice({ value: tradein.offerAmount }, tradein.currency) }}</dd>
        <dt>{{ t.outstanding_financing }}</dt>
        <dd>- {{ formatPrice({ value: tradein.outstandingFinanceAmount }, tradein.currency) }}</dd>
      </dl>
      <br />
      <dl>
        <dt>{{ t.receivable_tradein_amount }}</dt>
        <dd>{{ formatPrice({ value: tradein.totalAmount }, tradein.currency) }}</dd>
      </dl>
      <div class="actionButtons" v-if="showCompleteActions">
        <button :class="{ reject: true, loading: completing === 'reject' }" @click="completeOffer(false)">{{ t.reject_offer }}</button>
        <button :class="{ accept: true, loading: completing === 'accept' }" @click="completeOffer(true)">{{ t.accept_and_start_order }}</button>
      </div>
    </section>
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang'

export default {
  name: 'TradeinDetails',
  mixins: [langMixin('TRADEIN_DETAILS'), SeezSdk.vueQueryMixin],
  props: {
    tradeinId: { type: [Number, String], required: true },
    tradein: { type: Object, default: null },
    toListing: { type: String, required: true }
  },
  emits: ['tradeinUpdated'],
  data() {
    return {
      details: this.tradein,
      loading: false,
      completing: null,
      user: null
    }
  },
  computed: {
    showOffer() {
      return this.tradein.totalAmount > 0 //avoiding the unnecesary use of states
    },
    showCompleteActions() {
      if (this.tradein.state !== 'offerSent') return false // wrong state
      return true
    },
    registrationDate() {
      if (this.tradein?.registrationDate == null) return null

      const d = new Date(this.tradein.registrationDate)
      return Intl.DateTimeFormat(this.locale).format(d)
    }
  },
  methods: {
    getImage(url) {
      if (!url) return this.placeholder ?? `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
      return `${import.meta.env.VITE_SEEZ_BASE_URL}/image/500x0/${url}`
    },
    goToListing() {
      window.open(this.toListing.replace('{id}', this.tradein.listingId), '_blank').focus()
    },
    async completeOffer(accepted) {
      if (this.completing != null) return

      try {
        this.completing = accepted ? 'accept' : 'reject'
        const query = accepted ? 'mutation completeOffer($id: ID!) { acceptTradeInOffer(id: $id) { state }}' : 'mutation completeOffer($id: ID!) { rejectTradeInOffer(id: $id) { state }}'
        const result = await this.queryApi(query, { id: this.tradeinId })
        const newState = accepted ? result.acceptTradeInOffer.state : result.rejectTradeInOffer.state
        this.$emit('tradeinUpdated', { newState: newState, tradeinId: this.tradein.id, listingId: this.tradein.listingId })
      } catch (error) {
        console.error(error)
      } finally {
        this.completing = null
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.tradeinDetails {
  @include theme;

  > header {
    display: flex;
    justify-content: space-between;

    > h2,
    h3 {
      padding-inline-start: 1rem;
      margin: 0;
      text-align: start;
    }

    > .gotoButton {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;
      margin-inline-end: 1.5rem;
      border-radius: 0.4rem;
      cursor: pointer;
      color: #000000;
      font-size: 0.7rem;
      background-color: #f6f6f6;
      border: solid #00000018 1px;
      border-radius: 0.4rem;
      font-weight: 400;
      padding: 0.4rem;

      svg {
        margin-inline-end: 0.25rem;
        margin-block-start: -2px;
        // display: block;
      }
    }
  }

  > section.top {
    display: grid;
    grid-template-rows: repeat(4, auto);
    gap: 1rem;
    padding: 1rem;

    > .images {
      display: flex;
      gap: 1rem;

      > img {
        max-height: 5rem;
        max-width: 8rem;
        border-radius: 0.25rem;
      }
    }

    > div h4 {
      text-align: start;
      padding: 0;
      margin: 0;
    }

    > div p {
      text-align: start;
      padding: 0;
      margin: 0;
    }

    > dl {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 0;
      gap: 1rem 2rem;

      > dt {
        text-align: start;
      }

      > dt::after {
        content: ':';
      }

      > dd {
        text-align: start;
        font-weight: bold;
        padding: 0;
        margin: 0;
        text-align: right;
      }

      > .wide {
        grid-column: 1 / -1;
        text-align: left;
      }
    }
  }

  > section.bottom {
    background-color: var(--accented-background);
    padding: 2rem;
    border-radius: 0.25rem;

    > h4 {
      text-align: start;
      margin-block-start: 0;
    }

    > dl {
      grid-area: details;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0;
      gap: 0.25rem 2rem;

      > dt {
        text-align: start;
      }

      > dt::after {
        content: ':';
      }

      > dd {
        text-align: start;
        font-weight: bold;
        text-align: right;
        padding: 0;
        margin: 0;
      }
    }

    .actionButtons {
      width: 100%;
      text-align: center;
      display: flex;
      margin-block-start: 1rem;
      gap: 1rem;

      > button {
        font-family: var(--font-family), sans-serif;
        text-decoration: none;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0.532rem 0.5rem;
        border: none;
        border-radius: 2.75rem;
        background-color: var(--highlight);
        color: var(--background);
        cursor: pointer;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.188rem;

        &.accept {
          @include primaryButton;
        }

        &.reject {
          @include secondaryButton;

          color: #ff333a;
          border-color: #ff333a;
        }
      }
    }
  }
}
</style>
