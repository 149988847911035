<template>
  <div class="calculatorWrapper">
    <div v-if="loading" class="loader">
      <loader />
    </div>
    <div class="calculator">
      <div class="leftContent">
        <slot />
        <template v-if="listing == null">
          <label for="carPrice">{{ t['car_estimated_price'] }}</label>
          <span class="input">
            <input id="carPrice" v-model.number="carPrice" :placeholder="t['loan_is_below_santander']" type="number" />
            <span>{{ t['kr'] }}</span>
          </span>
          <sub v-if="error && carPrice" :class="{ error: loanBelowMin }">{{ t['loan_is_below_santander'] }}</sub>
        </template>
        <div class="percentage">
          <span v-if="error" class="error">* {{ error }}</span>
          <label for="downpayment">{{ t['preferred_down_payment'] }}</label>
          <!-- <span>{{ `${downPaymentPct} %` }}</span> -->
        </div>
        <span class="input" :class="{ error: downPaymentBelowMin }">
          <input id="downpayment" @input="e => onChangeDownpayment(e)" :value="downPayment" placeholder="Downpayment" />
          <span>{{ t['kr'] }}</span>
        </span>
        <div>
          <InfoIcon class="icon" />
          <p class="downPaymentInfo">Med minimum 20% udbetaling får du den bedste rente på dit billån. Ønsker du et lån med mindre end 20% udbetaling får du et lån i bilen uden pant, hvilket resulterer i en højere rente</p>
        </div>
        <sub v-if="downPaymentBelowMin" :class="{ error: downPaymentBelowMin }">{{ t['downpayment_is_required'] }}</sub>

        <label for="loanDurationMenu">{{ t['preferred_loan_duration_months'] }} {{ `(${t['months']})` }}</label>
        <div id="loanDurationMenu" class="loanDurationOptions">
          <button v-for="o in paymentTermsOptions" :key="o" :class="{ active: o === paymentTerms }" @click="setPaymentTermsOption(o)">{{ o }}</button>
        </div>

        <label v-if="interestTypes.length > 1" class="checkboxLabel" for="checkboxMenu">{{ t['interest_type'] }}</label>
        <div v-if="interestTypes.length > 1" id="checkboxMenu" class="checkboxMenu">
          <label v-for="interestType in interestTypes" :key="interestType" :for="interestType + 'input'" :class="{ active: value === interestType }">
            <input :id="interestType + 'input'" v-model="value" class="checkbox" :value="interestType" type="radio" />
            <span>{{ t[interestType] }}</span>
          </label>
        </div>
      </div>
      <div class="rightContent">
        <h1>{{ t['monthly_payment'] }}</h1>
        <h5>{{ purchaseType }}</h5>
        <p class="blue-text">
          {{ monthlyPayment ? `${monthlyPayment.toLocaleString(language)} ` : '-' }} <span>{{ `${t['kr_month']}` }}</span>
        </p>
        <table>
          <tr>
            <th>{{ t['loan_amount'] }}</th>
            <th>{{ t['term'] }}</th>
            <th>{{ t['aop_before_tax'] }}</th>
          </tr>
          <tr>
            <td>{{ loanValue }}</td>
            <td>{{ termMonths }}</td>
            <td>{{ aprValue }}</td>
          </tr>
        </table>
        <div class="paymentTerms">
          <sub v-html="disclaimer" />
        </div>
        <div class="financingBy">
          <p>{{ t['financed_by'] }}</p>
          <div class="imgContainer">
            <img :src="logoUrl" alt="santander" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
    <template v-if="loanDetails">
      <div class="loanDetailsHeader">
        <h2>{{ t['financing_details'] }}</h2>
        <button class="showLoanDetailsButton" @click="showLoanDetails = !showLoanDetails">{{ showLoanDetailsLabel }}</button>
      </div>

      <transition>
        <div v-if="showLoanDetails" class="loanDetails">
          <dl class="loanTable">
            <div v-for="loanDetail in loanDetails" :key="loanDetail.itemLabel">
              <dt>{{ t[loanDetail.itemLabel] }}</dt>
              <dd>
                <b>{{ loanDetail.value }}</b>
              </dd>
            </div>
          </dl>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import { debounce } from '../../logic'
import SeezSdk from '../../sdk.js'
import Loader from '../Loader.ce.vue'
import { langMixin } from '../lang'
import InfoIcon from '../../assets/info-icon.svg'

const InterestTypes = {
  FIXED: 'fixed',
  VARIABLE: 'variable'
}
Object.freeze(InterestTypes)

export default {
  name: 'SeezCalculator',
  components: { Loader, InfoIcon },
  mixins: [langMixin('CALCULATOR_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: { listing: { type: String, default: null }, vertical: { type: Boolean, default: false } },
  data() {
    return {
      aopBeforeTax: null,
      paymentTermsOptions: [12, 24, 36, 48, 60, 72, 84, 96],
      carPrice: null,
      apr: null,
      downPayment: null,
      minDownPayment: null,
      paymentTerms: 96,
      loanAmount: null,
      monthlyPayment: null,
      disclaimer: null,
      loading: false,
      downPaymentBelowMin: false,
      loanBelowMin: false,
      value: InterestTypes.VARIABLE,
      downPaymentPct: null,
      showLoanDetails: true,
      data: null,
      interestTypes: [],
      loanDetails: null,
      maxPaymentTerms: null,
      paymentTermsAboveMax: null,
      logo: null,
      error: null
    }
  },
  computed: {
    loanValue() {
      return this.loanAmount ? `${this.loanAmount.toLocaleString(this.language)} kr.` : '-'
    },
    aprValue() {
      if (this.apr) {
        const cappedApr = parseFloat(this.apr).toFixed(2)
        return `${cappedApr} %`
      } else {
        return '-'
      }
    },
    termMonths() {
      return !this.loading ? `${this.paymentTerms} ${this.t.months}` : '-'
    },
    showLoanDetailsLabel() {
      return this.showLoanDetails ? this.t.hide_loan : this.t.show_loan
    },
    purchaseType() {
      return this.downPaymentPct < 20 ? this.t.bank_loan : this.t.purchase_contract
    },
    logoUrl() {
      if (this.logo == null) return import.meta.env.VITE_IMAGES_URL + '/image/0x0/financing-providers/santander.png'
      return `${import.meta.env.VITE_SEEZ_BASE_URL}${this.logo}`
    }
  },
  watch: {
    carPrice() {
      this.calculate()
    },
    paymentTerms() {
      this.calculate()
    },
    value() {
      this.calculate()
    },
    maxPaymentTerms() {
      this.calculateLoanDuration()
      if (this.maxPaymentTerms < 96) {
        this.paymentTerms = this.maxPaymentTerms
        this.calculate()
      }
    },
    interestTypes() {
      if (this.interestTypes.length === 1) return (this.value = this.interestTypes[0])
    }
  },
  mounted() {
    if (this.listing) this.requestCalculation()
  },
  methods: {
    formatToCurrency(value = 0) {
      const strValue = String(value)
      const clean = strValue.replace('.', '').replace(',', '').replace(/\D/g, '')
      const result = new Intl.NumberFormat('da-DK', { currency: 'DKK' }).format(parseFloat(clean))
      if (result === 'NaN') return 0
      return result
    },
    formatCurrencyToNumber(stringValue) {
      return +stringValue.replace(/[^a-zA-Z0-9 ]/g, '')
    },
    onChangeDownpayment(event) {
      const value = event.target.value
      this.downPayment = this.formatToCurrency(value)
      const numberDownpayment = this.formatCurrencyToNumber(value)
      if ((numberDownpayment == null || numberDownpayment < this.minDownPayment) && numberDownpayment === this.minDownPayment) return
      this.percentage = this.percentageCalculation(numberDownpayment, this.carPrice)
      this.calculate()
    },
    calculateLoanDuration() {
      const numberOfMonths = Math.ceil(this.maxPaymentTerms / 12)

      const loanDurationInMonthsArray = Array.from({ length: numberOfMonths }, (item, index) => {
        const isNotDivisibleByNumberOfMonths = index * 12 + 12 > this.maxPaymentTerms
        if (index === 0) return 12
        if (isNotDivisibleByNumberOfMonths) return this.maxPaymentTerms
        return index * 12 + 12
      })

      if (loanDurationInMonthsArray.length > 0) return (this.paymentTermsOptions = loanDurationInMonthsArray)
    },
    calculate: debounce(async function () {
      this.requestCalculation()
    }, 1000),
    async requestCalculation() {
      let downPayment = null
      this.error = null
      try {
        const numberDownpayment = this.formatCurrencyToNumber(this.downPayment)
        downPayment = parseFloat(numberDownpayment)
      } catch (error) {
        //send null
      }
      if ((downPayment == null && this.listing == null) || !this.paymentTermsOptions.includes(this.paymentTerms)) return
      if (this.listing == null && (this.carPrice == null || this.carPrice <= 0)) return
      this.loanBelowMin = this.carPrice < 50000
      if (this.carPrice < 50000 && this.listing == null) return
      this.abortController?.abort()
      this.abortController = new AbortController()

      const query = `query loanCalculation($listingId: ID $downPayment: Float $paymentTerms: Int $amount: Float $interestType: InterestType) {
          loanCalculation(listingId: $listingId downPayment: $downPayment paymentTerms: $paymentTerms, amount: $amount interestType: $interestType) {
            aopBeforeTax
            financedAmount
            loanAmount
            downPayment
            downPaymentTotal
            downPaymentTradeIn
            downPaymentPct
            totalPayable
            totalLoanCost
            paymentTerms
            monthlyPayment
            nominalInterestRate
            interestType
            interestTypes
            interestRate
            apr
            disclaimer
            downPaymentBelowMin
            nominalInterestRate
            maxPaymentTerms
            paymentTermsAboveMax
            logo

          }
        }`

      const payload = { downPayment: downPayment, paymentTerms: this.paymentTerms, interestType: this.value }
      if (this.listing) payload.listingId = this.listing
      else payload.amount = this.carPrice

      this.loading = true
      try {
        const { loanCalculation: data } = await this.queryApi(query, payload)
        this.loanAmount = data.loanAmount
        if (this.downPayment == null) this.minDownPayment = data.downPayment
        this.data = data
        this.aopBeforeTax = data.aopBeforeTax
        this.downPayment = this.formatToCurrency(data.downPayment)
        this.monthlyPayment = data.monthlyPayment
        this.disclaimer = data.disclaimer
        this.downPaymentPct = data.downPaymentPct?.toFixed(0)
        this.apr = data.apr
        this.downPaymentBelowMin = data.downPaymentBelowMin && payload.downPayment != null
        this.loadLoanDetails(data)
        this.maxPaymentTerms = data.maxPaymentTerms
        this.paymentTermsAboveMax = data.paymentTermsAboveMax
        this.interestTypes = data.interestTypes
        this.logo = data.logo
        this.loading = false
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error)
          this.error = error.message
          this.loading = false
        }
      }
    },
    loadLoanDetails(data) {
      const loanDetailsArray = [
        { itemLabel: 'aop_before_tax', value: data.aopBeforeTax?.toFixed(2) + ' %' },
        { itemLabel: 'base_amount', value: `${data.loanAmount.toLocaleString(this.language)} kr.` },
        { itemLabel: 'down_payment_pct', value: data.downPaymentPct?.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' %' },
        { itemLabel: 'total_loan_costs', value: `${data.totalLoanCost.toLocaleString(this.language)} kr.` },
        { itemLabel: 'financed_amount', value: `${data.financedAmount.toLocaleString(this.language)} kr.` },
        { itemLabel: 'interest_type', value: this.t[data.interestType] },
        { itemLabel: 'nominal_interest', value: data.nominalInterestRate ? data.nominalInterestRate.toFixed(2) + '%' : '' },
        { itemLabel: 'interest_rate', value: data.interestRate?.toFixed(2) + '%' || '' },
        { itemLabel: 'total_repay', value: data.totalPayable?.toLocaleString(this.language) + ' kr.' }
      ]

      this.loanDetails = loanDetailsArray
    },
    setPaymentTermsOption(value) {
      this.paymentTerms = value
    },
    handleRadioValue() {
      this.fixed = !this.fixed
      this.floating = !this.floating
    },
    percentageCalculation(givenValue, totalValue) {
      return (100 * givenValue) / totalValue
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.calculatorWrapper {
  @include theme;

  position: relative;
  font-family: var(--base-font);

  input,
  button {
    font-family: var(--base-font);
  }

  .loader {
    position: absolute;
    z-index: 3;
    inset: 0;
  }

  .calculator {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--text-color);
    gap: 2rem;
    padding: 1rem 0rem;

    @media (min-width: 870px) {
      flex-direction: row;
    }

    > .leftContent {
      > h1 {
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0;
        padding: 0 0 2rem 0;
      }

      > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.16px;
      }

      label {
        font-size: 1rem;
        font-weight: 700;
      }

      > .percentage {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;

        > span {
          font-size: 12.8px;
          font-weight: 700;
        }
      }

      > .input {
        margin: 0.5rem 0;
        display: flex;
        position: relative;

        > input {
          font-size: 1rem;
          padding: 0.9rem 1rem;
          border-radius: 24px;
          width: 100%;
          border: 1px solid #e2e2e2;
          margin-block-end: 1rem;
        }

        > span {
          background-color: var(--highlight);
          border-radius: 0px 24px 24px 24px;
          color: var(--background);
          font-size: 14px;
          display: flex;
          align-items: center;
          position: absolute;
          inset-inline-end: 0;
          top: 0.12rem;
          padding: 1.05rem 1.5rem;

          @media (min-width: 768px) {
            top: 0;
            padding: 1.02rem 1.5rem;
          }
        }
      }

      div {
        display: flex;
        justify-content: center;
        gap: 0.1rem;

        svg {
          min-width: 1rem;
        }
        > .downPaymentInfo {
          margin: 0 0 1rem 0;
          padding: 0;
          font-size: 0.8rem;
        }
      }

      .error {
        color: red;
        > input {
          border-left-color: red;
          border-top-color: red;
          border-bottom-color: red;
        }

        > span {
          border-color: red;

          &::before {
            content: '!';
            color: red;
            position: absolute;
            inset-block-start: 1em;
            inset-inline-start: -4em;
            border: 1px solid red;
            border-radius: 2em;
            height: 1em;
            width: 1em;
            padding: 0.125em;
            text-align: center;
            line-height: 1em;
            font-weight: bold;
          }
        }
      }

      @media (min-width: 870px) {
        width: 55%;
      }

      > .loanDurationOptions {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @media (min-width: 1012px) {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        gap: 9px;
        margin: 1rem 0 2rem 0;

        > button {
          background-color: var(--background);
          border: none;
          padding: 14px 17px;
          cursor: pointer;
          border-radius: 6px;
          font-size: 14px;
          white-space: nowrap;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--text-color);
          max-height: 2.8rem;
        }

        > button:hover,
        .active {
          font-weight: 600;
          color: var(--highlight);
          max-height: 2.8rem;
        }

        > .active {
          border: 2px solid var(--highlight);
          font-weight: 700;
          max-height: 2.8rem;
        }
      }

      > .checkboxMenu {
        display: flex;
        gap: 1rem;
        padding-block-start: 1rem;

        > label {
          background-color: var(--background);
          border-radius: 8px;
          padding: 0.6rem 0.6rem;
          width: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          display: flex;

          > span {
            padding-inline-start: 0.5rem;
            font-weight: 400;
          }

          > input[type='radio' i] {
            margin: 3px 3px 3px 5px;
          }
        }

        .active {
          border: 2px solid var(--highlight);

          > span {
            font-weight: 600;
          }
        }
      }
    }

    > .rightContent {
      background-color: var(--background);
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
      position: relative;
      font-weight: 700;
      padding: 1em;
      width: 90%;

      margin: auto;

      @media (min-width: 415px) {
        max-width: none;
        margin: 0;
      }

      @media (min-width: 870px) {
        width: 30%;
        margin-inline-start: 0.5em;
        padding: 2em;
      }

      > h1 {
        font-size: 1rem;
        font-weight: 600;
        padding: 0;
        margin: 0;
        padding-block-end: 0.5rem;
      }

      > h5 {
        padding: 0;
        margin: 0;
        font-weight: 400;
      }

      .blue-text {
        color: var(--highlight);
      }

      > p {
        font-size: 2.8rem;

        @media (min-width: 768px) {
          font-size: 3.3rem;
        }

        line-height: 34px;
        margin: 0;
        padding: 2rem 0 2rem 0;

        > span {
          font-size: 1.2rem;

          @media (min-width: 768px) {
            font-size: 1.5rem;
          }

          padding-inline-start: 1rem;
          padding-block-start: 1rem;
        }
      }

      > table {
        width: 100%;
        font-size: 1rem;

        > tr {
          > th {
            text-align: start;
            color: #545454;
            font-weight: 400;

            @media (min-width: 870px) {
              width: 33%;
              padding-inline-end: 6px;
            }
          }

          > td {
            text-align: start;
            font-weight: 700;
            padding: 0.5em 0;
          }
        }
      }

      > .paymentTerms {
        font-size: 0.9rem;
        color: #757575;
        padding-block-start: 0.5em;
        font-weight: 400;

        > sub {
          line-height: 0.9rem;
        }

        > sub {
          margin-block-start: 2em;
        }
      }

      > .financingBy {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-block-start: 2.5em;
        background-color: reds;

        @media (min-width: 768px) {
          justify-content: flex-start;
          flex-direction: row;
        }

        > p {
          font-size: 0.9rem;
          font-weight: 600;
          color: #545454;
          padding: 0;
          margin: 0;
          white-space: nowrap;
        }

        > .imgContainer {
          width: 10.75em;
          padding-block-start: 0.3rem;

          @media (min-width: 768px) {
            padding-block-start: 0;
            padding-inline-start: 0.8em;
          }

          > img {
            width: 100%;
            height: auto;
          }
        }
      }

      > button {
        position: absolute;
        left: 2.5em;
        inset-block-start: -1.5em;
        border-radius: 24px;
        background: var(--highlight);
        border: none;
        color: var(--background);
        padding: 0.75em 1em;
        gap: 1rem;
        width: 12.938em;
        height: 3em;
        cursor: pointer;
        font-weight: 700;
        font-size: 1rem;

        &:hover {
          background-color: #0053cc;
        }
      }
    }

    sub {
      display: block;
      font-size: 0.75em;
      line-height: 1.2em;
      color: #afafaf;

      &.error {
        color: red;
      }
    }
  }

  .loanDetailsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h2 {
      font-size: 1em;
      font-weight: 700;
      color: var(--text-color);
    }

    > .showLoanDetailsButton {
      border: none;
      color: var(--highlight);
      background-color: transparent;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 700;
    }
  }

  .loanDetails {
    background-color: #ffffff;
    border-radius: 10px;
    color: var(--text-color);

    > .loanTable {
      display: grid;
      grid-template-columns: 1fr;

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      padding: 1rem 0;

      > div {
        display: flex;
        justify-content: space-between;
        padding: 0.8rem 0;
        font-size: 12.8px;
        margin-inline-start: 1rem;
        margin-inline-end: 1rem;
      }

      > div:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }
    }
  }
}
</style>
