<template>
  <div class="loadComponent" :style="loadingStyles">
    <LoadingSpinner class="spinner" />
    <p class="message">
      {{ message }}
    </p>
    <div class="sequentialDotsSpinner">
      <div class="dot" id="dot1" />
      <div class="dot" id="dot2" />
      <div class="dot" id="dot3" />
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '../../assets/spinner.svg'

export default {
  name: 'ChatLoading',
  components: { LoadingSpinner },
  props: {
    message: { type: String, required: true, default: 'Thinking' },
    chatMessageStyes: { type: Object, required: false, default: () => {} }
  },
  computed: {
    loadingStyles() {
      return {
        '--base-color': this.chatMessageStyes.messageColor
      }
    }
  }
}
</script>

<style lang="scss">
.loadComponent {
  display: flex;
  align-items: center;
  gap: 0.3em;

  > svg {
    path {
      fill: var(--base-color);
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 2s linear infinite;
}

@keyframes fadeIn {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.sequentialDotsSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5em;
}

.dot {
  width: 2px;
  height: 2px;
  background-color: var(--base-color);
  border-radius: 50%;
  margin-inline-end: 0.25em;
  opacity: 0;
  animation: fadeIn 1s infinite;
}

#dot1 {
  animation-delay: 0.1s;
}
#dot2 {
  animation-delay: 0.3s;
}
#dot3 {
  animation-delay: 0.5s;
}
</style>
