<template>
  <div class="toolTip" :class="positionClass">
    <slot />
    <div class="arrow" :class="positionClass" />
  </div>
</template>

<script>
const TooltipPosition = {
  TOP_RIGHT: 'topRight',
  TOP_LEFT: 'topLeft',
  BOTTOM_RIGHT: 'bottomRight',
  BOTTOM_LEFT: 'bottomLeft'
}

export default {
  name: 'ToolTip',
  props: {
    position: {
      type: String,
      default: '',
      validator(value) {
        return [TooltipPosition.TOP_RIGHT, TooltipPosition.TOP_LEFT, TooltipPosition.BOTTOM_RIGHT, TooltipPosition.BOTTOM_LEFT].includes(value)
      }
    }
  },
  computed: {
    positionClass() {
      return `toolTip-${this.position}`
    }
  }
}
</script>

<style lang="scss" scoped>
$arrow-size: 0.625em;
$arrow-color: #f9f9f9;
$tooltip-padding: 0.5em;
$tooltip-background: #f9f9f9;
$tooltip-border-color: #ddd;
$tooltip-border-radius: 0.25em;
$tooltip-box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.18);
$tooltip-z-index: 100;

.toolTip {
  position: relative;
  padding: $tooltip-padding;
  background-color: $tooltip-background;
  border-radius: $tooltip-border-radius;
  box-shadow: $tooltip-box-shadow;
  z-index: $tooltip-z-index;
  max-width: 11.25em;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    transform: rotate(180deg);
  }

  &-topRight {
    &::before {
      border-left: -0.125em solid transparent;
      border-right: 1.438em solid transparent;
      border-bottom: 0.938em solid $arrow-color;
      top: 100%;
      right: 0.25em;
    }
  }

  &-topLeft {
    &::before {
      border-left: 0.875em solid transparent;
      border-right: 0.313em solid transparent;
      border-bottom: 0.75em solid $arrow-color;
      top: 100%;
      left: 0.438em;
    }
  }

  &-bottomRight {
    &::before {
      border-left: 0.313em solid transparent;
      border-right: 1.438em solid transparent;
      border-top: 0.938em solid $arrow-color;
      bottom: 99%;
      right: 0.313em;
    }
  }

  &-bottomLeft {
    &::before {
      border-left: 0.625em solid transparent;
      border-right: 0.438em solid transparent;
      border-top: 0.688em solid $arrow-color;
      bottom: 98%;
      left: 0.313em;
    }
  }
}
</style>
