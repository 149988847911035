<template>
  <ul class=faqList>
    <li v-for="(faq, index) in faqs" :key="'faq' + (index + 1)" class="faqListItem" @click="handlePillClicked(faq)">
      <div>{{ faq }}</div>
      <svg class="pill-icon" width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.7192 6.36306C16.8975 6.52713 17 6.75683 17 6.99747C17 7.23811 16.8975 7.46417 16.7192 7.63189L10.0406 13.7573C9.67634 14.0927 9.09955 14.0781 8.75424 13.7281C8.40893 13.3781 8.42031 12.8239 8.7846 12.4921L13.8163 7.87253H0.910714C0.406027 7.87253 0 7.4824 0 6.99747C0 6.51255 0.406027 6.12242 0.910714 6.12242H13.8163L8.7808 1.5065C8.41652 1.17106 8.40513 0.620506 8.75045 0.270485C9.09576 -0.0795374 9.67254 -0.0904751 10.0368 0.241317L16.7154 6.3667L16.7192 6.36306Z" fill="#2D2D2D"/>
      </svg>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'FAQPills',
  props: {
    faqs: { type: Array, default: () => [] },
  },
  emits: ['question-selected'],
  methods: {
    handlePillClicked(faq) {
      this.$emit('question-selected', {text: faq, sender: 'user'})
    }
  }
}
</script>

<style lang="scss">
.faqList {
  padding: 0;
  margin: 0;
}

.faqListItem {
  margin-top: 0.5em;
  border-radius: 2em;
  padding: 0.75em;

  background: linear-gradient(var(--faq-gradient-background), var(--faq-gradient-background)) padding-box,
              linear-gradient(to right, #2D2D2D, #00FFC2) border-box;
  border: 1px solid transparent;
  
  display: flex;
  gap: 1.5em;
  align-items: center;
  justify-content: space-between;
  width: fit-content;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14);
  }
}

.pill-icon {
  min-width: 17px;
  fill: var(--faq-arrow-fill);
  transform: var(--icon-rotate);
}

</style>
