<template>
  <Modal @close="close">
    <div class="unavailableListingModal">
      <BanCircle />
      <h1>{{ t['car_is_not_available'] }}</h1>
      <p v-if="id && total === 0 && !custom">{{ t['we_will_notify_you'] }}</p>
      <p class="customText" />
      <p v-if="id && total > 0">{{ t['we_got_similar_cars'] }}</p>
      <div v-if="id && total !== 0" class="carouselWrapper">
        <Carousel :filters="`?related-to=${id}`" :to="to" :target="target" @loaded="onListingIsLoaded" />
      </div>
      <div class="buttonWrapper">
        <button aria-label="Search All" :class="{ double: total <= 0 }" @click="searchAll">{{ `${t['search_all']}` }}</button>
        <button v-if="id && total > 0" aria-label="View Similar" @click="viewSimilar">{{ t['view_similar'] }}</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../Modal.ce.vue'
import Carousel from '../Carousel/Carousel2.ce.vue'
import BanCircle from '../../assets/ban-circle.svg'
import { langMixin } from '../lang'
import SeezSdk from '../../sdk.js'

export default {
  name: 'UnavailableListingModal',
  components: { Modal, Carousel, BanCircle },
  mixins: [langMixin('UNAVAILABLE_LISTING_MODAL'), SeezSdk.vueQueryMixin],
  props: {
    id: { type: Number, default: null },
    to: { type: String, default: null },
    target: { type: String, default: null },
    custom: { type: Boolean, default: false }
  },
  emits: ['close', 'search', 'similar'],
  data() {
    return {
      total: null,
      loading: true,
      hideText: true
    }
  },
  methods: {
    onListingIsLoaded(e) {
      this.total = e.total
    },
    close() {
      this.$emit('close')
    },
    searchAll() {
      this.$emit('search')
    },
    viewSimilar() {
      this.$emit('similar')
    }
  }
}
</script>

<style lang="scss">
@import '../../base.scss';

.unavailableListingModal {
  @include normalize;
  @include theme;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    font-weight: bold;
    font-size: 1.5em;
    margin-block-start: 1.5rem;
    margin-block-end: 0.5rem;
  }

  > p {
    font-size: 1rem;
    font-weight: 400;
  }

  .carouselWrapper {
    margin-inline: -0.5em;
  }

  .buttonWrapper {
    margin-block-start: 2.625rem;
    display: grid;
    gap: 0.5rem;
    align-items: stretch;
    justify-items: stretch;
    width: 100%;

    > button {
      border-radius: 4em;
      border: 2px solid var(--highlight);
      background-color: var(--background);
      color: var(--highlight);
      padding: 0.6em 1.5em;
      font-size: min(1.1em, 4.5vw);
      font-weight: bold;
      font-family: var(--base-font);
      white-space: nowrap;
      cursor: pointer;
    }

    > button:first-of-type {
      background-color: var(--highlight);
      color: var(--background);
    }

    @media (min-width: 30rem) {
      grid-template-columns: 1fr 1fr;

      .double {
        grid-column-end: span 2;
      }
    }
  }

  > .loader {
    position: absolute;
  }
}
</style>
