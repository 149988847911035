<template>
  <div :data-test-id="testId" class="dateTimeSlot">
    <div>
      <input ref="dateInput" class="inputDateTimeSlot" v-model="selectedDate" type="date" @input="handleSelection" :placeholder="t.date ?? 't.date'" :class="{ 'has-value': selectedDate }" :min="startDate" :required="required" />
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
        <line x1="16" y1="2" x2="16" y2="6" />
        <line x1="8" y1="2" x2="8" y2="6" />
        <line x1="3" y1="10" x2="21" y2="10" />
      </svg>
    </div>
    <multi-select v-if="showSlot" v-model="selectedTimeSlot" :multi="false" :options="Object.values(timeSlots)" @update:model-value="handleSelection" :placeholder="t.selectOption ?? 't.selectOption'" />
  </div>
</template>
<script>
import MultiSelect from './MultiSelect.ce.vue'
import { formatDate } from '../logic'
import { langMixin } from './lang'

export default {
  components: { MultiSelect },
  mixins: [langMixin('general')],
  props: {
    testId: { type: String, default: null },
    modelValue: { type: String, default: null },
    timeSlots: { type: Array, required: true },
    startDateDelayInDays: { type: Number, default: null },
    showSlot: { type: Boolean, default: true },
    required: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedDate: '',
      selectedTimeSlot: null,
      internalValue: null
    }
  },
  computed: {
    startDate() {
      if (this.startDateDelayInDays) {
        let startDate = new Date()
        startDate.setDate(startDate.getDate() + this.startDateDelayInDays)
        return formatDate(startDate, 'yyyy-mm-dd')
      }
      return null
    }
  },
  watch: {
    internalValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  mounted() {
    if (this.modelValue) {
      const modelValueDate = new Date(this.modelValue)
      this.selectedDate = modelValueDate.toISOString().split('T')[0]
      if (this.timeSlots) {
        this.setTimeSlot(modelValueDate)
      }

      this.updateInternalValue()
    }
  },
  methods: {
    setTimeSlot(modelValueDate) {
      const localTime = modelValueDate.getHours()
      const formattedHour = localTime < 10 ? `${localTime}` : `${localTime}`
      if (this.timeSlots.find(ts => ts.startsWith(formattedHour))) {
        this.selectedTimeSlot = this.timeSlots.find(ts => ts.startsWith(formattedHour))
      }
    },
    handleSelection() {
      this.validateAndFixDate()
      this.updateInternalValue()
    },
    validateAndFixDate() {
      let date = new Date(this.selectedDate)

      if (date.getFullYear() > 9999) {
        const today = new Date()
        const currentYear = today.getFullYear()
        date.setFullYear(currentYear)
        this.selectedDate = date.toISOString().split('T')[0]
      }
    },
    updateInternalValue() {
      if (this.selectedDate && this.selectedTimeSlot) {
        let dateTime = new Date(this.selectedDate)
        const hours = parseInt(this.selectedTimeSlot.split(':')[0])
        dateTime.setHours(hours)
        this.internalValue = dateTime.toISOString()
      } else if (this.selectedDate) {
        this.internalValue = new Date(this.selectedDate).toISOString()
      }
    }
  }
}
</script>

<style lang="scss">
.dateTimeSlot > div > input[type='date'] {
  -webkit-appearance: none;
  width: -webkit-fill-available;
  appearance: none;
  padding-right: 30px;
  position: relative;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
    opacity: 0;
    color: transparent;
    cursor: pointer;

    @media screen and (min-width: 50rem) {
      width: 80%;
    }
  }
}

.dateTimeSlot > div {
  position: relative;

  svg {
    position: absolute;
    top: 30%;
    right: 1rem;
    pointer-events: none;
  }
}
</style>
