<template>
  <div class="loader"><Icon /></div>
</template>

<script>
import Icon from '@/assets/waitBlock.svg'

export default {
  name: 'SeezLoader',
  components: { Icon }
}
</script>

<style lang="scss">
@import '../base.scss';

.loader {
  // @include theme;

  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  backdrop-filter: blur(3px);

  > svg {
    height: clamp(2rem, 33%, 4rem);
    width: clamp(2rem, 33%, 4rem);
    fill: var(--highlight);
  }
}
</style>
