<template>
  <div class="tradeInOfferDetails" v-if="order.tradeIn">
    <div class="columns">
      <div class="column">
        <template v-for="field in filteredFields">
          <div v-if="!!getField(field)" :key="field.name" class="field">
            <strong>{{ t[field.name] ?? `t.${field.name}` }} </strong>
            {{ getField(field) }}
          </div>
        </template>
        <div v-if="commentField && !!getField(commentField)" class="field">
          <strong>{{ t[commentField.name] ?? `t.${commentField.name}` }}</strong>
          {{ getField(commentField) }}
        </div>
      </div>
    </div>
    <div v-if="picturesField" class="pictures">
      <div>
        <div class="make">{{ getField({ name: 'make' }) + ' ' + getField({ name: 'model' }) }}</div>
        <div class="variant" v-if="!!getSubtitleField">{{ getSubtitleField }}</div>
      </div>
      <div>
        <img v-for="picture in order.tradeIn[picturesField.name]" :key="picture.url" :src="getImage(picture.url)" @error="imageError = true" />
      </div>
    </div>
    <div v-if="order.tradeIn.offerAmount" class="offerDetails">
      <span> {{ t.offerDetails ?? 't.offerDetails ' }} </span>
      <div>
        <span>{{ t.offerAmount ?? 't.offerAmount' }}</span
        >{{ formatPrice({ value: order.tradeIn.offerAmount, currency: order.tradeIn.currency }) }}
      </div>
      <div v-if="order.tradeIn?.outstandingFinanceAmount">
        <span>{{ t.outstandingFinancingAmount ?? 't.outstandingFinancingAmount' }}</span
        >- {{ formatPrice({ value: order.tradeIn.outstandingFinanceAmount, currency: order.tradeIn.currency }) }}
      </div>
      <div v-if="order.tradeIn?.deduction">
        <span>{{ t.tradeInDeduction ?? 't.tradeInDeduction' }}</span
        >- {{ formatPrice({ value: order.tradeIn.deduction, currency: order.tradeIn.currency }) }}
      </div>
      <div v-if="order?.pricing?.tradeInAmount" class="tradeInAmount">
        <strong>{{ t.tradeInAmount ?? 't.tradeInAmount' }}</strong
        >{{ formatPrice({ value: order.pricing.tradeInAmount, currency: order.tradeIn.currency }) }}
      </div>
      <div class="actionButtons" v-if="order.tradeIn.state === 'offerSent'">
        <button :class="{ reject: true, loading: completing === 'reject' }" @click="completeOffer(false)">{{ t.reject_offer ?? 't.reject_offer' }}</button>
        <button :class="{ accept: true, loading: completing === 'accept' }" @click="completeOffer(true)">{{ t.accept_offer ?? 't.accept_offer' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../../../sdk.js'
import { langMixin } from '../../../lang'

export default {
  name: 'TradeInOfferDetails',
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    order: { type: Object, required: true }
  },
  emits: ['tradeInOfferUpdated'],
  data() {
    return {
      targetSiteId: null,
      loading: false,
      fields: null,
      commentField: null,
      picturesField: null,
      tradeInInfoModel: {}
    }
  },
  computed: {
    filteredFields() {
      return this.fields?.filter(field => !['make', 'model', 'variant', 'kilometrage'].includes(field.name))
    },
    getSubtitleField() {
      const mile = this.getField({ name: 'kilometrage' }) ? this.getField({ name: 'kilometrage' }).toLocaleString(this.language) + 'Km' : null
      return [this.getField({ name: 'variant' }), mile]?.filter(item => item)?.join(' • ')
    },
    showOffer() {
      return this.tradein.totalAmount > 0 //avoiding the unnecesary use of states
    },
    showCompleteActions() {
      if (this.tradein.state !== 'offerSent') return false // wrong state
      return true
    },
    registrationDate() {
      if (this.tradein?.registrationDate == null) return null

      const d = new Date(this.tradein.registrationDate)
      return Intl.DateTimeFormat(this.locale).format(d)
    }
  },
  mounted() {
    this.loadTargetSite()
  },
  methods: {
    async loadTargetSite() {
      try {
        this.loading = true
        const query = `{targetSiteByClientId(id: "${window.seezSdk.clientId ?? 'mp'}") {
            id
            layoutTemplates {
              name
              fields {
                name
                type
                area
                question
                values
                required
                query
                fields {
                  name
                  type
                  area
                  question
                  values
                  required
                  query
                }
              }
            }
          }
        }`
        const results = await this.queryApi(query)
        this.targetSiteId = results.targetSiteByClientId?.id
        this.fields = results.targetSiteByClientId?.layoutTemplates.find(sc => sc.name === 'tradeIn').fields
        // check for lookup field
        this.licensePlateLookupField = this.fields.find(f => f.type === 'licensePlateLookup')

        if (this.licensePlateLookupField?.fields) {
          this.fields = [...this.fields, ...this.licensePlateLookupField.fields]
        }

        this.picturesField = this.fields.find(f => f.name === 'pictures')
        if (this.picturesField) {
          const picturesIndex = this.fields.indexOf(this.picturesField)
          this.fields.splice(picturesIndex)
        }

        this.commentField = this.fields.find(f => f.name === 'customerComment')
        if (this.commentField) {
          const commentIndex = this.fields.indexOf(this.commentField)
          this.fields.splice(commentIndex)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    getImage(url) {
      if (!url) return this.placeholder ?? `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
      return `${import.meta.env.VITE_SEEZ_BASE_URL}/image/500x0/${url}`
    },
    getField(field) {
      if (field.type === 'date') {
        const date = new Date(this.order.tradeIn[field.name])
        return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
      }
      return this.t[this.order.tradeIn[field.name]] ?? this.order.tradeIn[field.name]
    },
    async completeOffer(accepted) {
      if (this.completing != null) return

      try {
        this.completing = accepted ? 'accept' : 'reject'
        const query = accepted ? 'mutation completeOffer($id: ID!) { acceptTradeInOffer(id: $id) { state }}' : 'mutation completeOffer($id: ID!) { rejectTradeInOffer(id: $id) { state }}'
        const result = await this.queryApi(query, { id: this.order.tradeIn.id })
        const newState = accepted ? result.acceptTradeInOffer.state : result.rejectTradeInOffer.state
        this.$emit('tradeInOfferUpdated', { newState: newState })
      } catch (error) {
        console.error(error)
      } finally {
        this.completing = null
      }
    }
  }
}
</script>

<style lang="scss">
.tradeInOfferDetails {
  .columns {
    display: flex;
    flex-wrap: wrap;

    .column {
      flex: 1;
      margin-right: 20px; /* Adjust spacing between columns */
    }
  }

  > .pictures {
    > div {
      gap: 1rem;
      display: flex;
      > img {
        max-height: 5rem;
        max-width: 8rem;
      }
    }
  }
}
</style>
