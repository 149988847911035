<template>
  <div class="deliveryInfo">
    <div>
      <span>{{ t.selectDeliveryType ?? 't.selectDeliveryType' }}</span>
      <div v-if="order.delivery.deliveryTypes.length > 1" class="selector">
        <div v-for="deliveryType in order.delivery.deliveryTypes" :key="deliveryType">
          <input type="radio" :id="deliveryType" :value="deliveryType" v-model="selectedDeliveryType" />
          <label :for="deliveryType"> {{ t[deliveryType] ?? deliveryType }} </label>
        </div>
      </div>
    </div>
    <div>
      <FormTemplate :key="formKey" :preloaded-data="input.delivery" :fields="formFields.delivery" :errors="appState.state === 'incomplete' ? appState?.errors : null" @updated="updateDeliveryInput" />
      <div v-if="formFields.addressLookup" class="addressTypes">
        <label>{{ t.whereDoYouWantItDelivered ?? 't.whereDoYouWantItDelivered' }}</label>
        <div class="selector addressTypesSelector">
          <input type="radio" id="sameAddress" :value="false" v-model="input.newAddress" />
          <label for="sameAddress"> {{ t.sameAddress ?? 't.sameAddress' }} </label>
          <input type="radio" id="newAddress" :value="true" v-model="input.newAddress" />
          <label for="newAddress"> {{ t.newAddress ?? 't.newAddress' }} </label>
        </div>
      </div>
      <FormTemplateAddress v-if="input.newAddress && formFields.addressLookup" :order-id="order.id" :preloaded-data="input.delivery.address" :address-lookup-field="formFields.addressLookup" @updated="updateAddressInput" />
      <div id="deliveryDateNote">
        <div>
          <QuestionNoteIcon />
          <span>{{ t.note ?? 't.note' }}</span>
        </div>
        <div>{{ t.deliveryDateNote ?? 't.deliveryDateNote' }}</div>
      </div>
      <div id="deliveryDateNoteAAM">
        <div>
          <QuestionNoteIcon />
          <span>{{ t.note ?? 't.note' }}</span>
        </div>
        <div>{{ t.deliveryNoteAAM ?? 't.deliveryNoteAAM' }}</div>
      </div>
      <FormTemplate v-if="formFields.addressFields" :key="formKey" :preloaded-data="input.delivery.address" :fields="formFields.addressFields" :errors="appState.state === 'incomplete' ? appState?.errors : null" @updated="updateAddressInput" />
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../../sdk.js'
import { langMixin } from '../../lang'
import { mergeObjects } from '../../../logic.js'
import FormTemplate from '@/components/FormTemplate/FormTemplate.ce.vue'
import FormTemplateAddress from '@/components/FormTemplateAddress/FormTemplateAddress.ce.vue'
import QuestionNoteIcon from '../../../assets/question-note.svg'

export default {
  name: 'DeliveryInfo',
  components: { FormTemplate, FormTemplateAddress, QuestionNoteIcon },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    order: { type: Object, required: true }
  },
  emits: ['persistInput'],
  data() {
    const deliveryType = this.order.delivery.type ?? this.order.delivery.deliveryTypes[0]
    const fields = this.order.listing.owner.layoutTemplates.find(sc => sc.name === 'delivery').fields.find(f => f.name === deliveryType).fields
    // setting values to an empty array stops FormTemplate from running any lookupGeneratorFunctions on that field
    if (fields.find(f => f.name === 'showroomId')) {
      fields.find(f => f.name === 'showroomId').values = []
    }

    // eslint-disable-next-line no-unused-vars
    const initialDeliveryInput = (({ deliveryTypes, ...o }) => o)(this.order.delivery)

    return {
      loading: false,
      formKey: 0,
      formFields: {
        delivery: fields,
        addressLookup: fields.find(f => f.type === 'addressLookup'),
        addressFields: this.getAddressFields(fields)
      },
      showrooms: [],
      selectedDeliveryType: deliveryType,
      input: {
        delivery: initialDeliveryInput,
        newAddress: this.useNewAddress()
      }
    }
  },
  computed: {
    appState() {
      return this.order?.appState?.steps?.find(s => s.name === 'delivery')
    }
  },
  watch: {
    input: {
      deep: true,
      handler: function () {
        let showroomId
        if (this.input.delivery.showroomId) {
          showroomId = this.showrooms?.find(sr => sr.address === this.input.delivery.showroomId)?.id ?? null
        }
        this.input.delivery.type = this.selectedDeliveryType
        this.$emit('persistInput', { delivery: { ...this.input.delivery, showroomId: showroomId, location: undefined }, newAddress: this.input.newAddress })
      }
    },
    selectedDeliveryType: {
      handler: function () {
        this.formFields.delivery = this.order.listing.owner.layoutTemplates.find(sc => sc.name === 'delivery').fields.find(f => f.name === this.selectedDeliveryType).fields
        this.formFields.addressLookup = this.formFields.delivery.find(f => f.type === 'addressLookup')
        this.formFields.addressFields = this.getAddressFields(this.formFields.delivery)
        this.input.delivery.type = this.selectedDeliveryType
        if (this.selectedDeliveryType === 'showroom') {
          this.populateShowroomValues()
        }
      }
    }
  },
  mounted() {
    this.populateShowroomValues()
  },
  methods: {
    getAddressFields(fields) {
      let addressLookupField = fields.find(f => f.name === 'address')
      let addressFields = []
      if (addressLookupField && addressLookupField?.type !== 'addressLookup') {
        addressFields = addressLookupField.fields
        addressLookupField = null
      }
      return addressFields
    },
    async populateShowroomValues() {
      if (this.formFields.delivery && this.selectedDeliveryType === 'showroom') {
        const showroomField = this.formFields.delivery.find(f => f.name === 'showroomId')

        if (!showroomField.values?.length) {
          if (showroomField && showroomField.type === 'dropdown') {
            this.showrooms = await this.queryShowroomValues()
          } else if (showroomField && showroomField.type === 'lookup' && showroomField.lookupGeneratorFunction && window[showroomField.lookupGeneratorFunction]) {
            this.showrooms = await this.queryShowroomsFromGlobalScope(showroomField.lookupGeneratorFunction)
          }
        }

        if (this.showrooms.length) {
          showroomField.values = this.showrooms.map(sr => sr.address)
          const newShowroom = this.input.delivery.showroomId ?? this.order?.listing?.showroom?.id
          if (newShowroom) {
            this.input.delivery.showroomId = this.showrooms.find(sr => sr.id === newShowroom || sr.address === newShowroom)?.address
            this.formKey += 1
          }
        }
      }
    },
    async queryShowroomValues() {
      const showroomsQuery = `
      {
        showrooms(dealershipIds: [${this.order?.listing?.owner?.id}]) {
          id
          name
          address
          location {latitude longitude}
        }
      }`

      const results = await this.queryApi(showroomsQuery)
      return results.showrooms?.filter(sr => !sr.location?.latitude && !sr.location?.longitude)
    },
    async queryShowroomsFromGlobalScope(generatorFunction) {
      const showrooms = await window[generatorFunction]()
      return showrooms
    },
    updateDeliveryInput(formInput) {
      this.input.delivery = mergeObjects(this.input.delivery, { ...formInput })
    },
    updateAddressInput(formInput) {
      this.input.delivery.address = mergeObjects(this.input.delivery.address, { ...formInput })
    },
    useNewAddress() {
      if (!this.order.delivery.address?.city && this.order.delivery.address?.postalCode && this.order.delivery.address?.street) {
        return false
      }

      if (this.order.customerInfo?.address?.city !== this.order.delivery.address?.city || this.order.customerInfo?.address?.postalCode !== this.order.delivery.address?.postalCode || this.order.customerInfo?.address?.street !== this.order.delivery.address?.street) {
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss">
.deliveryInfo {
  @media screen and (max-width: 36rem) {
    > :nth-child(2) {
      > .formTemplate {
        .dateTimeSlot {
          display: flex;
          flex-direction: column;
        }
      }

      .formTemplate .formContainer {
        display: flex;
        flex-direction: column;
      }
    }
  }

  #deliveryDateNote {
    display: none;
  }

  #deliveryDateNoteAAM {
    display: none;
  }
}
</style>
