<template>
  <div @click="handleWrapperClick" :class="{ inputWrapper: true, radius: isExpanded }" :style="chatInputStyles">
    <textarea ref="chatInput" :id="id" class="chatInput" v-model="inputText" @input="adjustTextareaHeight" @keydown="handleKeyDown" :placeholder="placeholder" :class="{ expanded: isExpanded, disabled }" :rows="textareaRows" :disabled="disabled" />
    <button id="chatInputSendButton" :class="['chatInputSendButton', { disabled: !inputText }, textDirection]" :disabled="!inputText || disabled" @click="sendMessage">
      <MessageIcon class="icon" />
    </button>
  </div>
</template>

<script>
import MessageIcon from '../../assets/message.svg'
import { langMixin } from '../lang'

export default {
  name: 'ChatInput',
  components: { MessageIcon },
  mixins: [langMixin('CHAT_COMPONENT_TRANSLATIONS')],
  props: {
    id: { type: String, required: true },
    placeholder: { type: String, required: true },
    theme: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    botConfig: { type: Object, default: () => {}, required: false }
  },
  emits: ['new-message'],
  data() {
    return {
      inputText: '',
      enterClicked: false,
      shiftEnterPressed: false,
      textareaRows: 1
    }
  },
  computed: {
    isExpanded() {
      return this.textareaRows > 1 || this.enterClicked || this.shiftEnterPressed
    },
    buttonIconTheme() {
      if (!this.botConfig?.iconTheme) return 'black'
      return this.botConfig?.iconTheme === 'light' ? 'white' : 'black'
    },
    chatInputStyles() {
      let inputRadius = this.theme.chatInputStyles.inputRadius
      let buttonRadius = this.theme.chatInputStyles.buttonRadius
      if (this.botConfig?.borderRadius && this.botConfig.borderRadius === 'square') {
        inputRadius = '8px'
        buttonRadius = '8px'
      }

      const handleColor = color => {
        if (color === 'Transparent' || !color) return this.theme.chatInputStyles.chatBgColor
        return color
      }

      return {
        '--button-bg-color': handleColor(this.botConfig.primaryColor),
        '--chat-bg-color': this.theme.chatInputStyles.chatBgColor,
        '--input-radius': inputRadius,
        '--button-radius': buttonRadius,
        '--chat-base-text-color': this.theme.chatBaseTextColor,
        '--input-border-color': this.theme.chatInputStyles.inputBorderColor,
        '--chat-placeholer-color': this.theme.chatInputStyles.chatPlaceholderColor,
        '--icon-color': this.buttonIconTheme,
        '--font-family': this.botConfig.font
      }
    }
  },
  watch: {
    inputText() {
      if (this.inputText === '') {
        this.textareaRows = 1
      }
    },
    disabled(isDisabled) {
      if (!isDisabled) {
        this.$nextTick(() => {
          this.$refs.chatInput.focus()
        })
      }
    }
  },
  methods: {
    sendMessage() {
      if (this.inputText.trim() !== '') {
        const message = {
          text: this.inputText,
          sender: 'user'
        }
        this.$emit('new-message', message)
        this.inputText = ''
      }
    },
    calculateNumRows(textarea) {
      const lineHeight = parseInt(getComputedStyle(textarea).lineHeight, 10)
      const rows = Math.floor(textarea.scrollHeight / lineHeight)
      this.textareaRows = rows < 1 ? 1 : rows
    },
    adjustTextareaHeight(event) {
      const textarea = event.target
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
      this.enterClicked = false
      this.calculateNumRows(textarea)

      if (this.inputText.trim() === '') {
        this.shiftEnterPressed = false
      }
    },
    handleWrapperClick() {
      this.$nextTick(() => {
        this.$refs.chatInput.focus()
      })
    },
    handleKeyDown(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        this.sendMessage()
        this.shiftEnterPressed = false
      } else if (event.key === 'Enter' && event.shiftKey) {
        this.shiftEnterPressed = true
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../base.scss';

.inputWrapper {
  @include theme;
  position: relative;
  border-radius: var(--input-radius);
  border: 1px solid var(--input-border-color);
  background: var(--chat-bg-color);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 13.75em;
  padding: 1em 0;
  min-width: 95%;
  margin: auto;
}

.chatInput {
  width: 70%;
  margin-inline-end: 1em;
  height: 1.25em;
  line-height: 1.25em;
  min-height: 1.25em;
  max-height: 1.25em;
  resize: none;
  border: none;
  background: var(--chat-bg-color);
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  color: var(--chat-base-text-color);
  font-size: 1em;
  font-family: var(--font-family);

  @media screen and (min-width: 768px) {
    font-size: 0.875em;
    width: 85%;
  }
}

textarea::placeholder {
  color: var(--chat-placeholer-color);
  font-family: var(--font-family);
}

.icon {
  path {
    fill: var(--icon-color);
  }
  transform: var(--icon-rotate);
}
.chatInput.expanded {
  max-height: 12.5em;
  transition: max-height 0.6s ease;
  border-radius: 2px;
}

.radius {
  border-radius: 0.5em;
}

.chatInputSendButton {
  position: absolute;
  width: 2.875em;
  height: 2.875em;
  background-color: var(--button-bg-color);
  border-radius: var(--button-radius);
  border: none;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.chatInputSendButton.ltr {
  right: 0.375em;
}

.chatInputSendButton.rtl {
  left: 0.375em;
}

.disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
</style>
