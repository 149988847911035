export function parseUrlFilters(queryString) {
  if (queryString == null) return {}
  try {
    const sp = new URLSearchParams(queryString)
    const keys = [...new Set(sp.keys())]
    keys.sort()
    const result = {}
    const intFields = ['brands', 'models', 'families', 'year-min', 'year-max', 'kilometrage-min', 'kilometrage-max', 'price-min', 'price-max', 'body-types', 'transmissions', 'fuel-types', 'doors', 'page', 'related-to']
    const floatFields = ['engine-size-max']
    const arrayFields = ['models', 'families', 'body-types', 'colors', 'drive-types', 'fuel-types', 'related-to']
    for (const key of keys) {
      const values = sp
        .getAll(key)
        .flatMap(c => c.split(','))
        .map(c => {
          if (floatFields.includes(key)) return parseFloat(c)
          else if (intFields.includes(key)) return parseInt(c)
          else return c
        })

      result[key.replace(/[_-]./g, x => x[1].toUpperCase())] = values?.length > 0 ? (arrayFields.includes(key) ? values : values[0]) : null
    }

    if (['wholesale', 'wholesaleLease'].includes(result.priceType)) result._isWholesale = true

    return result
  } catch (error) {
    console.warn(`error parsing ${queryString}`)
    return {}
  }
}

export function formatFilterForApi(filter) {
  const clean = {
    freeText: filter.freeText,
    yearMin: filter.yearMin,
    yearMax: filter.yearMax,
    kilometrageMin: filter.kilometrageMin,
    kilometrageMax: filter.kilometrageMax,
    brands: filter.brands ? [filter.brands] : null,
    models: filter.models,
    families: filter.families,
    bodyTypes: filter.bodyTypes,
    fuelTypes: filter.fuelTypes,
    transmissions: filter.transmissions ? [filter.transmissions] : null,
    colors: filter.colors,
    sort: filter.sort ?? '-created_date',
    numberOfDoorsMax: filter.doors,
    numberOfDoorsMin: filter.doors,
    driveTypes: filter.driveTypes,
    engineSizeMax: filter.engineSizeMax ?? null,
    relatedTo: filter.relatedTo,
    targetSiteIds: filter.targetSiteIds,
    recommendedFor: filter.recommendedFor,
    tags: filter.tags ? [filter.tags] : null,
  }
  if (filter.hasPromotion !== 'false' && filter.hasPromotion) {
    clean.hasPromotion = true
  }
  if (Number(filter.numberSeat)) {
    clean.numberSeat = Number(filter.numberSeat)
  }
  if (filter.isNoviceDrivable !== 'false' && filter.isNoviceDrivable) {
    clean.isNoviceDrivable = true
  }
  if (filter.certified === 'true') {
    clean.certified = true
  }
  if (filter._isWholesale && filter.priceType == null) {
    clean.prices = [{ type: 'wholesale' }, { type: 'wholesaleLease' }]
  } else if (filter.priceType) {
    clean.prices = { type: filter.priceType }
    if (filter.priceMax > 0) clean.prices.maxPrice = filter.priceMax
    if (filter.priceMin > 0) clean.prices.minPrice = filter.priceMin
  }
  const result = Object.fromEntries(Object.entries(JSON.parse(JSON.stringify(clean))).filter(e => e[1] != null))
  return Object.keys(result).length > 0 ? result : null
}

export function debounce(func, timeout = 300) {
  let timer
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

export function isValidEmail(email) {
  // eslint-disable-next-line no-control-regex
  const emailRegex = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/
  return email != null && typeof email === 'string' && email.length > 0 && emailRegex.test(email)
}

export async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message)
  if (crypto.subtle == null) {
    console.warn('Page is not HTTPS, not encryting')
    return ''
  }
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
}

//converts a string into something healthy for urls
export function slugify(text) {
  return text
    ?.trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w]+/g, '-')
    .replace(/-+$/g, '')
    .toLowerCase()
}

export function toIsoString(date) {
  const tzo = -date.getTimezoneOffset()
  const dif = tzo >= 0 ? '+' : '-'
  const pad = num => (num < 10 ? '0' : '') + num

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    // '.' + date.getMilliseconds() + // pad to 3 digits
    dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' + pad(Math.abs(tzo) % 60)
}

export function formatDate(date, format) {
  const availableFormats = ['yyyy-mm-dd', 'dd-mm-yyyy', 'mm-dd-yyyy']

  if (availableFormats.includes(format)) {
    const year = date.getFullYear()
    let month = date.getMonth() + 1
    if (month < 10) {
      month = '0' + month
    }
    let day = date.getDate()
    if (day < 10) {
      day = '0' + day
    }

    return format.replace('yyyy', year)
      .replace('mm', month)
      .replace('dd', day)
  }

  return date
}

export function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime()
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
}

export function mergeObjects(target, source) {
  let mergedTarget = { ...target }

  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (typeof source[key] === 'object' && source[key] !== null) {
        if (!Object.prototype.hasOwnProperty.call(mergedTarget, key)) {
          mergedTarget[key] = {}
        }
        mergeObjects(mergedTarget[key], source[key])
      } else if (source[key] !== null) {
        mergedTarget[key] = source[key]
      }
    }
  }

  return mergedTarget
}

// // export function getDb(name, version) {
// //   let dbReq = indexedDB.open(name, version)

// //   return new Promise((resolve, reject) => {
// //     dbReq.onupgradeneeded = (event) => {
// //       let notes = event.target.result.createObjectStore('notes', { autoIncrement: true })
// //       console.log(notes)
// //       resolve(event.target.result)
// //     }
// //     dbReq.onsuccess = (event) => resolve(event.target.result)
// //     dbReq.onerror = reject
// //   })
// // }

// // export async function getFavorites() {
// //   const db = await getDb('seez', 1)
// //   let tx = db.transaction(['favorites'], 'readonly');
// //   let store = tx.objectStore('favorites');
// //   let req = store.get(1);

export const delay = (milliseconds = 2000) => new Promise(resolve => setTimeout(resolve, milliseconds))

export function validateQid(source, qid) {
  const qidRegex = /^([\u0660-\u0669]|\d){11}$/
  const AAM = 'aam'
  return (source === AAM && qid && !qidRegex.test(qid)) ? true : false
}

export function calculateCountdown(timeDifference) {
  const millisecondsInOneSecond = 1000
  const millisecondsInOneMinute = millisecondsInOneSecond * 60
  const millisecondsInOneHour = millisecondsInOneMinute * 60
  const millisecondsInOneDay = millisecondsInOneHour * 24
  const differenceInDays = timeDifference / millisecondsInOneDay
  const remainderDifferenceInHours = (timeDifference % millisecondsInOneDay) / millisecondsInOneHour
  const remainderDifferenceInMinutes = (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute
  const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond

  return {
    remainingDays: Math.floor(differenceInDays),
    remainingHours: Math.floor(remainderDifferenceInHours),
    remainingMinutes: Math.floor(remainderDifferenceInMinutes),
    remainingSeconds: Math.floor(remainderDifferenceInSeconds)
  }
}