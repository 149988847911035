<template>
  <div :ref="id" @click="changeTheme" class="chatMessage" :style="messageStyles">
    <UserAvatar :id="role" :avatar-theme="chatAvatarStyles[activeUserChat]" :bot-config="botConfig" :url="url" />
    <time class="date">{{ dateStampFormatted }}</time>
    <ChatLoading v-if="loading" :message="message" :chat-message-styes="chatMessageStyes" />
    <div v-else-if="words" :class="{ message: true, error }">
      <span  v-for="(word, index) in words" :key="index"  v-html="word" />
    </div>
    <div v-else :class="{ message: true, error }">
      <div v-html="message" />
      <slot v-if="showFaqs" name="faqs" />
    </div>
  </div>
</template>
<script>
import UserAvatar from './UserAvatar.ce.vue'
import ChatLoading from './ChatLoading.ce.vue'
import { langMixin } from '../lang'

export default {
  name: 'ChatMessage',
  components: { UserAvatar, ChatLoading },
  mixins: [langMixin('CHAT_COMPONENT_TRANSLATIONS')],
  props: {
    message: { type: String , default: ''},
    role: { type: String, required: true },
    id: { type: String, required: true },
    date: { type: [String, Date], required: true },
    chatAvatarStyles: { type: Object, required: true },
    chatMessageStyes: { type: Object, required: true },
    lastChild: { type: Boolean, required: true, default: false },
    botConfig: { type: Object, default: () => {} },
    words: { type: Array , default: null },
    showFaqs: { type: Boolean, default: false }
  },
  data() {
    return {
      htmlTypedMessage: '',
      typingSpeed: 8,
      typedMessage: '',
      typingIndex: 0,
      activeUserChat: 'userAvatarStyles'
    }
  },
  computed: {
    loading() {
      return this.id === 'loading'
    },
    error() {
      return this.id === 'error'
    },
    url() {
      return this.activeUserChat === 'userAvatarStyles' ? this.botConfig.userAvatarImage : this.botConfig.botAvatarImage || ''
    },
    messageStyles() {
      return {
        '--font-family': this.botConfig.font,
        '--date-color': this.chatMessageStyes.dateColor,
        '--message-font-family': this.chatMessageStyes.messageFontFamily,
        '--message-color': this.chatMessageStyes.messageColor,
        '--button-bg-color': this.chatMessageStyes.buttonColor,
        '--button-text-color': this.chatMessageStyes.buttonTextColor
      }
    },
    dateStampFormatted() {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      }

      const language = this.language

      const formatDate = date => {
        return new Intl.DateTimeFormat(language, options).format(date)
      }
      const parsedDate = new Date(this.date)
      const formattedDate = formatDate(parsedDate)
      return formattedDate
    }
  },
  mounted() {
    this.defineTheme()
  },
  methods: {
    defineTheme() {
      this.activeUserChat = this.role === 'user' ? 'userAvatarStyles' : 'ceaserAvatarStyles'
    },
  }
}
</script>
<style lang="scss">
.date,
.message {
  padding: 0;
  margin: 0;
  overflow: auto;

  a {
    color: var(--message-color);
    font-weight: 600;
  }
}
.chatMessage {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.25em 0.75em;
  text-align: start;

  > :first-child {
    min-width: 40px;
    grid-row: span 2;
  }

  > :last-child {
    width: 100%;
    grid-column: 2;
    grid-row: 2;
  }
}

.date {
  color: var(--date-color);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.message {
  margin-top: 0.2em;
  color: var(--message-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  white-space: pre-wrap;
  font-family: var(--font-family);
}

.message.error {
  color: red;
}
</style>
