<template>
  <form v-if="formFields && !onSuccess" @submit.prevent="formSubmitted" class="dynamicInquiryForm" :class="{ defaultStyles: loadDefaultStyles }">
    <h2 v-if="t">{{ `${t[leadType]?.heading} ${t.inquiry}` }}</h2>
    <ListingSelector v-if="selectedListingDetails" :listings-details="listingsDetails" :selected-listing-details="selectedListingDetails" @listing-selected="handleListingSelected" />
    <!-- USER INFORMATION START -->
    <fieldset v-if="formFields.customerInfo">
      <legend>{{ t.contactInformation }}</legend>

      <FormTemplate :translation-node="t" :preloaded-data="inquiryData" :fields="formFields.customerInfo.fields" @updated="updateInquiry" />
    </fieldset>
    <!-- VEHICLE INFORMATION START -->
    <fieldset v-if="formFields.vehicleInfo && showVehicleFields" class="vehicleForm">
      <legend>{{ t.vehicleInformation }}</legend>
      <FormTemplate :translation-node="t" :preloaded-data="inquiryData" :fields="formFields.vehicleInfo.fields" @updated="updateInquiry" />
    </fieldset>
    <!-- LEAD TYPE INFORMATION START -->
    <fieldset class="leadTypeFieldset" v-if="formFields[leadType]" :translationNode="t" :class="`${leadType}Form`">
      <legend>{{ `${t[leadType]?.legend}` }}</legend>
      <p v-if="leadTypeErrorMessage" class="leadTypeError">{{ t[leadTypeErrorMessage] }}</p>
      <FormTemplate :translation-node="t" :preloaded-data="inquiryData" :fields="dynamicAppointmentFields" @updated="updateInquiry" />
    </fieldset>
    <!-- LEGAL INFORMATION START -->
    <fieldset class="legalForm" v-if="formFields.legal">
      <legend>{{ t.privacy }}</legend>
      <p v-html="t.legalText?.replace(/{dealershipName}/g, targetSite.name)" />
      <!-- QUESTION BOOLEAN CHECKBOX -->
      <div class="question" v-for="(field, index) in formFields.legal.fields" :key="index">
        <label class="checkboxWrapper">
          <input :name="field.name" :data-test-id="`${field.name}Checkbox`" v-model="legalTerms[field.name]" :required="field.required" type="checkbox" :id="`${field.name}Checkbox`" true-value="true" false-value="false" />
          <div class="description" v-html="field.description" />
        </label>
      </div>
    </fieldset>
    <!-- ERROR HANDLING  START-->
    <p class="error" v-if="errorMessage">{{ t[errorMessage] }}</p>
    <input data-test-id="inputSubmit" v-if="!onSuccess" type="submit" :value="`${t.submit} ${t[leadType]?.heading} ${t.request}`" />
  </form>
  <!-- STATES HANDLING START-->
  <Success v-if="onSuccess" :translation-node="t" />
  <Loader v-if="loading || submitting || !formFields" />
</template>

<script>
import SeezSdk from '../../sdk'
import { langMixin } from '../lang'
import FormTemplate from '../FormTemplate/FormTemplate.ce.vue'
import ListingSelector from './ListingSelector.ce.vue'
import Loader from '../Loader.ce.vue'
import Success from './Success.ce.vue'

const InquiryTypes = {
  SERVICE: 'service',
  PARTS: 'parts',
  TEST_DRIVE: 'testDrive',
  GENERAL: 'general'
}

const errorTranslationKeys = {
  INQUIRY: 'errorCreatingInquiry',
  LISTING: 'errorListing',
  DEALERSHIP: 'errorDealershipLoad',
  HOLIDAYS: 'errorTestDriveHoliday',
  NO_LISTING: 'errorNoListing',
  SHOWROOM_CLOSED: 'errorShowroomClosed',
  SELECT_SHOWROOM: 'errorSelectLocation',
  NO_TIME_SLOTS: 'noTimeSlots'
}

const fieldsSections = {
  CUSTOMER_INFO: 'customerInfo',
  VEHICLE_INFO: 'vehicleInfo',
  LEGAL: 'legal',
  ...InquiryTypes
}

export default {
  name: 'DynamicInquiryForm',
  components: { FormTemplate, ListingSelector, Loader, Success },
  mixins: [SeezSdk.vueQueryMixin, langMixin('DYNAMIC_INQUIRY_FORM_TRANSLATIONS')],
  props: {
    id: { type: String, default: '' },
    listing: { type: String, default: '' },
    leadType: { type: String, default: InquiryTypes.GENERAL }
  },
  emits: ['update'],
  data() {
    return {
      targetSite: null,
      loadDefaultStyles: false,
      loading: false,
      submitting: false,
      errorMessage: '',
      onSuccess: false,
      listingsDetails: [],
      selectedListingDetails: null,
      inquiryData: {},
      formFields: null,
      legalTerms: {},
      payload: null,
      locationOptions: [],
      timeSlotsOptions: [],
      leadTypeErrorMessage: '',
      openingHours: {}
    }
  },
  computed: {
    showVehicleFields() {
      return [InquiryTypes.SERVICE, InquiryTypes.PARTS].includes(this.leadType)
    },
    dynamicAppointmentFields() {
      const formattedFields = this.formFields[this.leadType].fields.map(field => {
        if (field.name === 'date') {
          return { ...field, area: '2 / 1 / 3 / 4' }
        }
        if (field.name === 'time') {
          return { ...field, values: this.timeSlotsOptions, enabled: this.timeSlotsOptions.length > 0, area: '2 / 4 / 3 / 8' }
        }
        if (field.name === 'location') {
          return { ...field, values: this.locationOptions, area: '1 / 1 / 2 / 8' }
        }

        return {
          ...field
        }
      })

      return formattedFields
    }
  },
  watch: {
    'inquiryData.date': 'generateTimeSlots',
    'inquiryData.location': 'handleSlotsByLocation',
    t() {}
  },
  mounted() {
    this.loading = true
    Promise.all([this.loadListings(), this.loadTargetSite()]).then(() => (this.loading = false))
  },
  methods: {
    async loadListings() {
      if (this.listing == null || this.listing === '') return
      const ids = this.listing.split(',')

      try {
        const { listingsByIds } = await this.queryApi(
          `query listings($ids: [ID!]!) {
  listingsByIds(ids: $ids) {
    id
    name
    variant
    year
    images
    kilometrage
    kmUnit
    externalId
    referenceNumber
    
    vehicle{
      locatedAt {
        id
        plainAddress
         openingHours {
        testDriveTimeSlotSize
        holidays
        week {
          from
          to
        }
      }
      }
    }
    dealership {
      id
      plainAddress
      
      workingHours {
        dayOfWeek
        startTime
        endTime
      }
    }
    locatedAt {
      openingHours {
        testDriveTimeSlotSize
        holidays
        week {
          from
          to
        }
      }
    }
  }
}`,
          { ids }
        )
        this.listingsDetails = listingsByIds
        this.selectedListingDetails = this.listingsDetails[0] ?? null
        this.inquiryData.location = this.listingsDetails[0].vehicle.locatedAt.id

        if (this.leadType === InquiryTypes.TEST_DRIVE) {
          this.openingHours = this.listingsDetails[0].vehicle.locatedAt.openingHours
        }

        if (ids) {
          this.handleListingLocationOptions()
        }
      } catch (e) {
        console.error('Error loadListing', e)
        this.errorMessage = errorTranslationKeys.LISTING
      }
    },
    async loadTargetSite() {
      const query = `{
  currentTargetSite {
    id
    name
    dealerships {
      id
      name
      plainAddress
      openingHours {
        testDriveTimeSlotSize
        holidays
        week {
          from
          to
        }

      }
      children {
        id
        plainAddress
        openingHours {
          testDriveTimeSlotSize
        holidays
        week {
          from
          to
        }

        }
      }
    }
    customerSupport {
      email
      phoneNumber
    }
    urlConfig {
      privacyPolicyUrl
      termsAndConditionsUrl
    }
    layoutTemplates {
      name
      cmsFields
      cmsRequiredFields
      fields {
        name
        type
        values
        area
        regex
        question
        infoLabel
        placeholder
        query
        required
        visible
        enabled
        startDateDelay
        default
        translationNode
        acceptedFiles
      }
    }
  }
}`

      try {
        const { currentTargetSite } = await this.queryApi(query)
        if (!currentTargetSite) {
          this.errorMessage = errorTranslationKeys.DEALERSHIP
        }
        this.targetSite = currentTargetSite

        if (!this.selectedListingDetails) {
          this.locationOptions = this.handleLocation(currentTargetSite.dealerships)
        }

        this.formFields = this.createFieldsObjectMap(currentTargetSite.layoutTemplates)
      } catch (e) {
        console.error('Error loadTargetSite', e)
        this.errorMessage = errorTranslationKeys.DEALERSHIP
      }
    },
    async formSubmitted(e) {
      this.loading = true
      this.errorMessage = false
      this.onSuccess = false

      const formData = Object.fromEntries(new FormData(e.target))
      const formattedFields = this.formatPayload(formData)

      const payload = {
        inquiryType: this.leadType,
        targetSiteId: this.targetSite.id,
        inquiryData: formattedFields
      }

      if (this.selectedListingDetails) {
        payload.listingId = this.selectedListingDetails.id
      }

      const query = `
mutation createInquiry($inquiryType: InquiryType!, $targetSiteId: ID!, $listingId: ID, $inquiryData: [InquiryDataInput!]!) {
  createInquiry(
    inquiryType: $inquiryType
    targetSiteId: $targetSiteId
    listingId: $listingId
    inquiryData: $inquiryData
  ) {
    id
    inquiryData {
      key
      value
    }
    inquiryType
  }
}`

      try {
        const result = await this.queryApi(query, payload)

        if (!result) {
          this.errorMessage = errorTranslationKeys.INQUIRY
        }

        this.onSuccess = true
        this.inquiryData = {}
      } catch (e) {
        console.log(e)
        this.errorMessage = errorTranslationKeys.INQUIRY
      } finally {
        this.loading = false
      }
    },
    updateInquiry(newData) {
      this.leadTypeErrorMessage = ''
      this.inquiryData = { ...this.inquiryData, ...newData }
    },
    handleListingSelected(listing) {
      this.selectedListingDetails = listing
      this.setBookingTimeToInitialValues()
      this.inquiryData.date = ''
      this.handleListingLocationOptions()
      this.inquiryData = { ...this.inquiryData, location: listing?.vehicle?.locatedAt?.id }
    },
    handleListingLocationOptions() {
      this.locationOptions = [
        {
          id: this.selectedListingDetails?.vehicle?.locatedAt?.id,
          name: this.selectedListingDetails?.vehicle?.locatedAt?.plainAddress
        }
      ]
    },
    createFieldsObjectMap(layoutTemplates) {
      //TODO: should come from BE to be fully dynamic
      const formSections = [fieldsSections.CUSTOMER_INFO, fieldsSections.VEHICLE_INFO, this.leadType, fieldsSections.LEGAL]
      const fieldsObject = {}
      formSections.forEach(key => {
        const fieldData = layoutTemplates.find(template => template.name === key) || {}
        fieldsObject[key] = fieldData
      })
      return fieldsObject
    },
    handleLocation(dealershipArr = []) {
      let results = []

      dealershipArr.forEach(d => {
        if (d.plainAddress && d.plainAddress.trim() !== '') {
          results.push({
            id: d.id,
            name: d.plainAddress.trim()
          })
        }

        results = results.concat(
          d.children
            .filter(l => l.plainAddress && l.plainAddress.trim() !== '')
            .map(l => ({
              id: l.id,
              name: l.plainAddress.trim()
            }))
        )
      })

      const uniqueResults = new Map(results.map(result => [result.name, result]))

      return Array.from(uniqueResults.values()).sort((a, b) => a.name.localeCompare(b.name))
    },
    handleSlotsByLocation(locationId) {
      if (!this.targetSite) return

      this.setBookingTimeToInitialValues()
      this.inquiryData.date = ''

      const findLocationById = (data, id) => {
        if (data.id === id) {
          return data
        }
        if (data.children && data.children.length > 0) {
          for (const child of data.children) {
            const found = findLocationById(child, id)
            if (found) {
              return found
            }
          }
        }

        return null
      }

      const selectedLocation = findLocationById(this.targetSite.dealerships[0], locationId)
      if (!selectedLocation) return
      this.openingHours = selectedLocation.openingHours
    },
    setBookingTimeToInitialValues() {
      this.timeSlotsOptions = []
      this.leadTypeErrorMessage = ''
      this.inquiryData.time = ''
    },
    getTimeSlots(from, to, duration) {
      const timeFrom = this.getMinutes(from)
      const timeTo = this.getMinutes(to)
      const result = []
      if (timeFrom != null && timeTo != null && duration > 0) {
        for (let current = timeFrom; current < timeTo; current += duration) {
          let h = Math.floor(current / 60).toString()
          if (h.length === 1) h = '0' + h
          let m = (current % 60).toString()
          if (m.length === 1) m = '0' + m
          result.push(`${h}:${m}`)
        }
      }
      return result
    },
    getMinutes(time) {
      const parts = /^(\d{1,2})(?::(\d{1,2}))?$/g.exec(time)
      if (parts == null) return null
      const hours = parseInt(parts?.[1])
      const minutes = parseInt(parts?.[2] ?? '0')
      return hours * 60 + (minutes ?? 0)
    },
    generateTimeSlots(newDateValue) {
      if (!newDateValue) {
        this.timeSlotsOptions = []
        return
      }

      this.setBookingTimeToInitialValues()
      const date = new Date(this.inquiryData.date)
      const dayIndex = date.getUTCDay()
      const currentDate = date.toISOString().split('T')[0]

      // Check if there are specific opening hours available
      if (!this.openingHours || !this.openingHours.week) {
        // If no opening hours are available, generate time slots from 9am to 5pm
        const normalizedFrom = '09:00' // Start from 9am
        const normalizedTo = '17:00' // End at 5pm

        const slots = this.getTimeSlots(normalizedFrom, normalizedTo, 15)
        this.timeSlotsOptions = slots

        // Display a message indicating no specific opening hours
        this.leadTypeErrorMessage = errorTranslationKeys.NO_TIME_SLOTS
        return
      }

      // Check for holidays
      if (this.openingHours.holidays && this.openingHours.holidays.includes(currentDate + 'T00:00:00.000Z')) {
        this.leadTypeErrorMessage = errorTranslationKeys.HOLIDAYS
        return
      }

      const daySchedule = this.openingHours.week[dayIndex]

      if ((!daySchedule && !daySchedule?.from) || !daySchedule?.to) {
        this.leadTypeErrorMessage = errorTranslationKeys.SHOWROOM_CLOSED
        return
      }

      const normalizedFrom = daySchedule.from.includes(':') ? daySchedule.from : `${daySchedule.from}:00`
      const normalizedTo = daySchedule.to.includes(':') ? daySchedule.to : `${daySchedule.to}:00`

      const startTime = normalizedFrom.split(':').map(Number)
      const endTime = normalizedTo.split(':').map(Number)

      let currentTime = new Date(date)
      currentTime.setUTCHours(startTime[0], startTime[1], 0, 0)

      const endDateTime = new Date(date)
      endDateTime.setUTCHours(endTime[0], endTime[1], 0, 0)

      const slots = this.getTimeSlots(normalizedFrom, normalizedTo, this.openingHours.testDriveTimeSlotSize ?? 15)
      this.timeSlotsOptions = slots
    },
    formatPayload(obj) {
      const keyValueArray = []
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          const nestedArray = this.formatPayload(value)
          nestedArray.forEach(item => keyValueArray.push({ key: `${key}.${item.key}`, value: item.value }))
        } else {
          keyValueArray.push({ key, value })
        }
      }
      return keyValueArray
    }
  }
}
</script>

<style lang="scss">
@import '@/base.scss';
@import '@/defaultStyles';

.dynamicInquiryForm {
  @include theme;
  height: auto;
  background-color: var(--chat-bg-color, white);

  .leadTypeFieldset {
    position: relative;

    .leadTypeError {
      color: red;
      padding: 0;
      margin: 0;
      text-align: center;
      font-size: 0.7rem;
    }
  }

  .error {
    color: red;
    width: 100%;
    text-align: center;
  }

  .success {
    border-radius: 52px;
    color: white;
    padding: 12px 0;
    background-color: #009a33;
    width: 100%;
    margin: auto;
    text-align: center;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;

    p {
      margin: 0;
    }
  }

  .loader {
    position: absolute;
    inset: 0;
    z-index: 10;
  }

  .checkboxWrapper {
    display: flex;
    align-items: center;
    justify-content: start;
  }
}

.dynamicInquiryForm.defaultStyles {
  @include dynamicInquiryForm;
}
</style>
