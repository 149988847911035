<template>
  <p v-if="error" class="error">{{ error }}</p>
  <Loader v-if="loading" />
  <div v-else class="tableContainer">
    <table :class="{ comparisonTable: true, defaultStyles: loadDefaultStyles }">
      <thead>
        <tr>
          <th v-for="(attribute, index) in tableAttributes" :key="attribute.id" class="headerCell">
            <img v-if="index !== 0" :src="attribute.image" />
            <div v-if="feature(attribute.fuelType?.name)" class="feature">
              <ElectricIcon v-if="feature(attribute.fuelType.name) === 'electric'" />
              <HybridIcon v-if="feature(attribute.fuelType.name) === 'hybrid'" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in comparisonData" :key="index">
          <td v-for="(item, key) in row" :key="key" class="data-cell" :class="{ bold: item === t['overview'] }">
            <div v-if="typeof item === 'object'">
              <!-- Implement toggle -->
            </div>
            <div v-else>
              {{ item }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SeezSdk from '@/sdk.js'
import { langMixin } from '../lang'
import Loader from '../Loader.ce.vue'
import ElectricIcon from '@/assets/electric.svg'
import HybridIcon from '@/assets/hybrid.svg'

export default {
  name: 'CarComparisonTable',
  components: { Loader, ElectricIcon, HybridIcon },
  mixins: [langMixin('ComparisonTable'), SeezSdk.vueQueryMixin],
  props: { ids: { type: String, default: '89568,89814' }, listingKeys: { type: String, default: '' }, customRows: { type: String, default: '' } },
  data() {
    return {
      loading: true,
      error: '',
      tableAttributes: [],
      comparisonData: [],
      fuelType: null,
      resizeObserver: null,
      parentWidth: null,
      loadDefaultStyles: false //FIXME: this is a workaround given the ATG rush. Default styles should come from the backend.
    }
  },
  computed: {
    processedIds() {
      const idsArray = this.ids.split(',').filter(id => id.trim() !== '')
      return idsArray.length >= 2 ? idsArray.slice(0, 3) : []
    }
  },
  watch: {
    ids(newIds) {
      const idsArray = newIds.split(',').filter(id => id.trim() !== '')
      if (idsArray.length < 2 || idsArray.length > 3) {
        this.error = this.t.error_max_min_listings
      } else {
        this.error = ''
        this.fetchListings()
      }
    },
    t() {
      this.fetchListings()
    }
  },
  methods: {
    feature(fuelName) {
      if (['El', 'Electricity'].includes(fuelName)) return 'electric'
      if (['Hybrid', 'Hybrid (Benzin)', 'Hybrid (Diesel)'].includes(fuelName)) return 'hybrid'
      return null
    },
    async fetchListings() {
      if (this.processedIds.length < 2) {
        this.error = 'Not enough listings to compare.'
        this.loading = false
        return
      }

      const ids = this.ids.split(',').slice(0, 3)

      const query = `{listingsByIds(ids: [${ids}]) { id name variant year retailPrice { value currency } emiPrice { value currency emi { paymentTerms } } leasePrice { value currency lease { duration }  } fuelType { id name } driveType transmission { id name } bodyType { id name } kilometrage kmUnit fuelConsumption engineSize images greenTax co2Emission numDoors numSeats hp range numOwners }}`
      const result = await this.queryApi(query)
      const selectedListings = result.listingsByIds
      this.listings = selectedListings
      this.setTableData(selectedListings)

      this.loading = false
    },
    createTableHeading(listings) {
      const attributes = ['']

      listings.forEach(listing => {
        const placeholder = `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
        const imageUrl = listing.images?.length ? `${import.meta.env.VITE_SEEZ_BASE_URL}/image/300x0/${listing.images[0]}.webp` : placeholder

        attributes.push({
          name: `${listing.year} ${listing.name}`,
          image: imageUrl,
          fuelType: listing.fuelType
        })
      })

      return attributes
    },
    handleVariant(listing) {
      return `${listing?.variant || '-'} • ${this.formatNumber(listing.kilometrage)} ${listing.kmUnit ?? 'Km'} • ${listing.bodyType?.name || '-'}`
    },
    createTableRows(listings) {
      const rows = []

      const allNullFor = key => listings.every(listing => !listing[key] || listing[key].value === null)

      const getValue = (listing, keyPath, formatPriceFunc) => {
        const attributeCheckMap = {
          retailPrice: listing.retailPrice,
          emiPrice: listing.emiPrice,
          leasePrice: listing.leasePrice,
          driveType: listing.driveType,
          fuelConsumption: listing.fuelConsumption,
          engineSize: listing.engineSize,
          transmission: listing.transmission?.name,
          variant: this.handleVariant(listing),
          greenTax: { value: listing.greenTax, currency: listing.retailPrice?.currency ?? 'DKK' },
          co2Emission: listing.co2Emission,
          numDoors: listing.numDoors,
          numSeats: listing.numSeats,
          hp: listing.hp,
          range: listing.range,
          numOwners: listing.numOwners
        }

        if (keyPath === 'overview') {
          return ''
        }

        if (keyPath === 'variant') {
          return attributeCheckMap.variant
        }

        if (keyPath === 'transmission.name' && attributeCheckMap.transmission) {
          return this.t[attributeCheckMap.transmission]
        }

        if (keyPath === 'co2Emission' && listing.co2Emission) {
          return `${attributeCheckMap.co2Emission} ${this.t.co2Emission}`
        }

        if (keyPath === 'hp' && listing.hp) {
          return `${attributeCheckMap.hp} ${this.t.horsePowerUnit}`
        }

        if (keyPath === 'range' && listing.range) {
          return `${attributeCheckMap.range} ${this.t.rangeUnit}`
        }

        if (['retailPrice', 'emiPrice', 'leasePrice', 'greenTax'].includes(keyPath) && attributeCheckMap[keyPath]?.value) {
          let priceString = formatPriceFunc(attributeCheckMap[keyPath], 'DKK')
          if (keyPath === 'emiPrice') {
            priceString += ` (${listing.emiPrice.emi.paymentTerms} ${this.t.months})`
          }
          if (keyPath === 'leasePrice') {
            priceString += ` (${listing.leasePrice.lease.duration} ${this.t.months})`
          }
          return priceString
        }

        const getAttributeValue = key => {
          const value = key.split('.').reduce((acc, k) => (acc && acc[k] ? acc[k] : null), listing)
          if (key === 'fuelConsumption') {
            return value !== null ? `${value} ${this.t.km_l}` : this.t.no_answer
          } else if (key === 'engineSize') {
            return value !== null ? `${value} ${this.t.liter}` : this.t.no_answer
          } else if (key === 'driveType') {
            return this.t[value] || this.t.no_answer
          }
          return value || this.t.no_answer
        }

        return getAttributeValue(keyPath)
      }

      // Function to create a row for a given attribute
      const createRow = (listings, attributeKey) => {
        const translationKey = attributeKey.split('.')[0]
        const row = { attribute: this.t[translationKey] }
        listings.forEach((listing, index) => {
          row[`comparison${String.fromCharCode('A'.charCodeAt(0) + index)}`] = getValue(listing, attributeKey, this.formatPrice)
        })
        return row
      }

      const defaultAttributes = ['name', 'variant', 'retailPrice', 'overview', 'fuelType.name', 'transmission.name', 'fuelConsumption', 'engineSize', 'greenTax', 'co2Emission', 'numDoors', 'numSeats', 'hp', 'numOwners']
      const listingAttributeKeys = this.listingKeys ? this.listingKeys.split(',') : defaultAttributes

      // Custom rows received as string
      // Example: "attribute=Engine Size,comparisonA:1.5 L,comparisonB: 1.4 L,comparisonC:1.5 L;attribute=Fuel Type,comparisonA:Diesel,comparisonB: Petrol,comparisonC:Electric"
      const customRowsArray = this.transformStringToMultipleObjects(this.customRows)

      if (this.customRows && customRowsArray.length > 0) {
        return customRowsArray
      }

      listingAttributeKeys.forEach(item => {
        if (!allNullFor(item.split('.')[0])) {
          rows.push(createRow(listings, item))
        }
      })

      const priceAttributes = ['retailPrice', 'leasePrice', 'emiPrice']

      const lastPriceIndex = listingAttributeKeys.reduce((acc, item, index) => {
        return priceAttributes.includes(item) ? index : acc
      }, -1)

      let insertIndex = rows.findIndex(row => row.attribute === this.t[listingAttributeKeys[lastPriceIndex]]) + 1

      if (defaultAttributes.indexOf('overview') <= lastPriceIndex && defaultAttributes.indexOf('overview') !== -1) {
        insertIndex -= 1
      }

      const overviewRow = createRow(listings, 'overview')
      rows.splice(insertIndex, 0, overviewRow)

      return rows
    },
    transformStringToMultipleObjects(inputString) {
      const rows = inputString.split(';')

      return rows.map(row => {
        const pairs = row.split(',')

        const rowObject = {}
        pairs.forEach(pair => {
          const [key, value] = pair
            .replace(':', '=')
            .split('=')
            .map(item => item.trim())
          rowObject[key] = value
        })

        return rowObject
      })
    },
    setTableData(listings) {
      const tableHeaderAttributes = this.createTableHeading(listings)
      const tableRows = this.createTableRows(listings)

      this.tableAttributes = tableHeaderAttributes
      this.comparisonData = tableRows
    }
  }
}
</script>

<style lang="scss">
@import '../../defaultStyles';

.error {
  color: red;
}

.headerCell {
  position: relative;
}

.feature {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  display: flex;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 7px;
  padding: 0.25rem 0.438rem;

  > svg {
    display: inline-block;
    height: 1em;
    fill: white;
  }
}

.tableContainer {
  overflow: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;

  .comparisonTable {
    table-layout: fixed;

    .bold {
      font-weight: 600;
    }

    th > img {
      width: 100%;
      height: 6rem;
      object-fit: cover;
      border-radius: 6px;
    }
  }
}

// start default styles
.comparisonTable.defaultStyles {
  @include comparisonTable;
}
// end default styles
</style>
