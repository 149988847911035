<template>
  <div class="search-select" ref="searchSelect">
    <div class="input-container">
      <input :data-test-id="id" :id="id" :name="name" ref="inputSearch" type="text" v-model="inputValue" :class="{ error: showNotFound }" @focus="showOptions = true" @input="debouncedFilterOptions" @keydown.down.prevent="navigateOptions('down')" @keydown.up.prevent="navigateOptions('up')" @keydown.enter.prevent="handleSelect" @keydown.esc="showOptions = false" :placeholder="placeholder" />
      <button v-if="inputValue" @click="clearInput" :class="['clear-button', { error: showNotFound }]" ref="clearButton">&times;</button>
      <button @click="clickToggleOptions" v-if="!inputValue" :class="['chevron-down', { 'rotate-up': showOptions }]">
        <svg width="10" height="6" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.5 1.56 7.53 7.28C7.34 7.44 7.16 7.5 7 7.5 6.81 7.5 6.63 7.44 6.47 7.31L.47 1.56C.16 1.28.16.78.44.5.72.19 1.22.19 1.5.47L7 5.72 12.47.47C12.75.19 13.25.19 13.53.5 13.81.78 13.81 1.28 13.5 1.56Z" fill="#757575" />
        </svg>
      </button>
    </div>
    <ul v-if="showOptions && filteredOptions.length > 0" class="options-list">
      <li v-for="(option, index) in filteredOptions" :key="index" :tabindex="0" :class="{ highlighted: index === highlightedIndex }" @mousedown.prevent="selectOption(index)" @focus="highlightIndex(index)" @keydown.enter.prevent="selectOption(index)">
        {{ option.value }}
      </li>
    </ul>
    <div v-if="showNotFound" class="not-found">{{ errorMessage }}</div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: { type: [String, Number, Array], default: null },
    options: { type: Array, default: () => [{ id: '', value: '' }] },
    errorMessage: { type: String, default: 'This item is not on the list of items' },
    placeholder: { type: String, default: 'Select an item' },
    name: { type: String, default: 'search-dropdown' },
    id: { type: String, default: 'input-search-dropdown' }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputValue: '',
      showOptions: false,
      showNotFound: false,
      filteredOptions: [],
      highlightedIndex: -1,
      timeoutId: null,
      internalOptions: [],
      preventClickOutside: false,
      isRtl: document?.dir === 'rtl'
    }
  },
  watch: {
    modelValue(newValue) {
      if (newValue) {
        const option = this.options?.find(opt => opt.id === newValue || opt.value === newValue)
        if (option) {
          this.inputValue = option.value
        }
      } else {
        this.inputValue = ''
      }
    },
    options(v) {
      if (v.length > 0) {
        if (this.modelValue) {
          const selectedOption = v.find(i => i.id === this.modelValue)
          this.inputValue = selectedOption.value
        }
      }
      this.internalOptions = v
      this.filterOptions()
    },
    inputValue(newV, prevV) {
      if (newV.length > 0 && newV.length < prevV.length) {
        this.showOptions = true
      }
      this.debouncedFilterOptions()
    },
    showNotFound(v) {
      if (v) {
        this.showOptions = false
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
    this.internalOptions = this.options
    if (this.modelValue) {
      const option = this.options?.find(opt => opt.id === this.modelValue || opt.value === this.modelValue)
      if (option) {
        this.inputValue = option.value
      }
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    clickToggleOptions() {
      this.showOptions = !this.showOptions
    },
    filterOptions() {
      const search = this.inputValue.toLowerCase()
      this.filteredOptions = this.internalOptions.filter(option => option.value.toLowerCase().includes(search))
      this.highlightedIndex = -1
      this.showNotFound = this.filteredOptions.length === 0 && this.inputValue.length > 0

      if (this.showNotFound) {
        this.$nextTick(() => {
          this.$refs.clearButton?.focus()
        })
      }
    },
    debouncedFilterOptions() {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(this.filterOptions, 300)
    },
    navigateOptions(direction) {
      if (direction === 'down') {
        this.highlightedIndex = (this.highlightedIndex + 1) % this.filteredOptions.length
      } else if (direction === 'up') {
        this.highlightedIndex = (this.highlightedIndex - 1 + this.filteredOptions.length) % this.filteredOptions.length
      }
      this.scrollToHighlighted()
    },
    handleSelect() {
      const highlightedItem = this.filteredOptions.find((i, index) => index === this.highlightedIndex)
      if (highlightedItem) {
        this.inputValue = highlightedItem.value
        this.showOptions = false
        this.$emit('update:modelValue', highlightedItem.id)
        this.showNotFound = false
        this.$nextTick(() => {
          this.$refs.clearButton?.focus()
        })
      }
    },
    selectOption(index = this.highlightedIndex) {
      if (index >= 0 && index < this.filteredOptions.length) {
        const selectedOption = this.filteredOptions[index]
        if (selectedOption) {
          this.$emit('update:modelValue', selectedOption.id)
          this.inputValue = selectedOption.value
          this.showOptions = false
          this.showNotFound = false
          this.$nextTick(() => {
            this.$refs.clearButton?.focus()
          })
        }
      }
    },
    highlightIndex(index) {
      this.highlightedIndex = index
    },
    scrollToHighlighted() {
      this.$nextTick(() => {
        const container = this.$el.querySelector('.options-list')
        const highlighted = container.querySelector('.highlighted')
        if (highlighted) {
          container.scrollTop = highlighted.offsetTop - container.offsetTop
        }
      })
    },
    clearInput() {
      this.preventClickOutside = true
      this.inputValue = ''
      this.filteredOptions = this.internalOptions
      this.showNotFound = false
      this.showOptions = true
      this.$nextTick(() => {
        this.$refs.inputSearch?.focus()
        this.showOptions = true
        console.log(this.filteredOptions)
      })
    },
    handleClickOutside(event) {
      if (this.preventClickOutside) {
        this.preventClickOutside = false
        return
      }
      //the normal way to check for "click outside" does not work through shadow-dom, so here we go
      if (this.$el == null) return
      for (const child of this.$el.children) {
        const b = child.getBoundingClientRect()
        if (event.clientX >= b.left && event.clientX < b.right && event.clientY >= b.top && event.clientY < b.bottom) return
      }
    }
  }
}
</script>
<style lang="scss">
.search-select {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  > .not-found {
    position: absolute;
    color: red;
    font-size: 0.9rem;
    bottom: -0.5rem;
  }

  .options-list li {
    cursor: pointer;
    padding: 0.5rem;
  }

  .options-list li.highlighted {
    background: var(--highlight);
    color: white;
  }

  .input-container {
    width: 100%;
    display: flex;

    .chevron-down {
      position: absolute;
      right: 0.5rem;
      top: 45%;
      transform: translateY(-65%);
      font-size: 1.5rem;
      color: #ccc;
      background-color: transparent;
      border: none;
      transition: transform 0.2s ease;
      cursor: pointer;
    }

    .rotate-up {
      transform: translateY(-40%) rotate(180deg);
    }

    .clear-button {
      position: absolute;
      top: 37%;
      width: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: #ccc;
      right: v-bind('isRtl ? "auto" : "0.5rem"');
      left: v-bind('isRtl ? "0.5rem" : "auto"');

      &.error {
        color: red;
      }
    }

    .clear-button:hover {
      color: #000;
    }

    .options-list {
      position: absolute;
      width: 100%;
      border: 1px solid #ccc;
      background: white;
      z-index: 1000;
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 10rem;
      overflow: auto;
    }
  }

  input {
    font-size: 1rem;
    padding: 0.9rem 1rem;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid #e2e2e2;
    margin-block-end: 1rem;

    &.error {
      border: 1px solid red;

      &:focus {
        outline: none;
      }
    }
  }
  ul {
    list-style: none;
    position: absolute;
    border-radius: 0.5rem;
    top: 2.5rem;
    left: 0;
    right: 0;
    height: auto;
    max-height: 15rem;
    min-height: 1rem;
    overflow: auto;
    background-color: white;
    padding: 1rem;
    z-index: 999;
    border: 1px solid var(--accent);

    li {
      cursor: pointer;
      padding: 0.5rem 0;

      &:hover {
        opacity: 0.7;
        color: var(--accent);
      }
    }
  }
}
</style>
