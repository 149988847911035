<template>
  <Loader v-if="loading" />
  <div v-else class="clearChat">
    <div class="icon">
      <ClearChatIcon />
    </div>
    <h2 class="heading">{{ t.clearChat }}</h2>
    <p class="text">{{ t.clearChatText }}</p>
    <div class="buttons">
      <button @click="cancel" aria-label="cancel button" class="cancelButton">{{ t.cancel }}</button>
      <button @click="clearChat" aria-label="clear history" class="clearButton">
        <TrashIcon class="trashIcon" />
        <span>{{ t.clear }}</span>
      </button>
    </div>
  </div>
</template>
<script>
import { langMixin } from '@/components/lang.js'
import { callCeaserApi, APIOperations } from './api'
import Loader from '../Loader.ce.vue'
import ClearChatIcon from '../../assets/clear-chat.svg'
import TrashIcon from '../../assets/trash-bin.svg'

export default {
  name: 'ClearChat',
  components: { Loader, ClearChatIcon, TrashIcon },
  mixins: [langMixin('ClearChat')],
  props: { id: { type: String, default: '' } },
  emits: ['clear', 'cancelClearChat'],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async clearChat() {
      this.loading = true
      try {
        const message = await callCeaserApi(APIOperations.CLEAR_CHAT, { clear_at: new Date().toISOString() }, this.id)

        if (message) {
          window.dispatchEvent(new CustomEvent('clearChat'))
          this.$emit('clear')
          this.loading = false
        }
      } catch (error) {
        console.error('Error deleting chat history:', error)
        this.loading = false
      }
    },
    cancel() {
      window.dispatchEvent(new CustomEvent('cancelClearChat'))
      this.$emit('cancelClearChat')
    }
  }
}
</script>
<style lang="scss">
.clearChat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  border-radius: 0.625em;
  border: 1px solid var(--border-color);

  .trashIcon {
    path {
      fill: white;
    }
  }

  .icon {
    width: 5.25em;
    height: 5.25em;
    fill: #fff2f3;
    box-shadow: 0px 3px 5px 1px rgba(255, 0, 0, 0.15) inset;
    background-color: #fff2f3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
  }

  .heading {
    text-align: center;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .text {
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    max-width: 37.688em;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin: auto;
    padding: auto;

    button {
      cursor: pointer;
      white-space: nowrap;
      padding: 0.5em;
      background-color: transparent;
      border-radius: 52px;
      color: var(--chat-base-text-color);
      border: 1px solid var(--chat-base-text-color);

      @media screen and (min-width: 50em) {
        padding: 0.75em 1em;
        font-size: 1em;
        min-width: 9em;
      }

      &:hover {
        opacity: 0.9;
      }
    }

    .clearButton {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
      color: white;
      background-color: red;
      border: none;
    }
  }
}
</style>
